<!--
 * @Author: your name
 * @Date: 2021-11-15 19:00:47
 * @LastEditTime: 2022-02-18 18:28:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\views\controlPanel\components\case-list.vue
-->
<template>
  <div class="case-list">
    <div class="case-list-header">
      <i
        class="el-icon-back"
        style="font-weight: bold; margin-right: 15px; cursor: pointer"
        @click="back"
      ></i>
      <el-popover
        placement="bottom-start"
        width="234"
        :visible-arrow="false"
        ref="popoverRef"
        @show="openViewBox"
        trigger="click"
      >
        <div class="view-container">
          <div class="view-search">
            <el-input
              :placeholder="$t('vue_label_commonobjects_view_searchview')"
              prefix-icon="el-icon-search"
              v-model="searchView"
            >
            </el-input>
          </div>
          <div class="view-item-box">
            <div
              :class="['view-item', { 'view-item-active': item.id === viewId }]"
              v-for="(item, index) in viewList"
              :key="index"
              @click="changeView(item)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="default-view" slot="reference">
          {{ viewName }}
          <span>
            <i class="el-icon-caret-bottom"></i>
          </span>
        </div>
      </el-popover>
      <!-- 要取消当前默认视图的设置，可将其他视图设为默认视图 -->
      <div class="thumbtack">
        <div
          class="tubiao"
          @click="setDefaultView"
          v-if="this.viewDefaultId !== this.viewId"
        >
          <svg class="icon inline_block" aria-hidden="true">
            <use href="#icon-thumbtack"></use>
          </svg>
        </div>
        <el-tooltip
          v-else
          trigger="click"
          :content="$t('vue_label_commonobjects_view_currentviewset')"
          placement="top-start"
        >
          <div class="tubiao">
            <svg class="icon inline_block" aria-hidden="true">
              <use href="#icon-thumbtack1"></use>
            </svg>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="case-list-content">
      <div class="case-list-searchbox">
        <el-input
          :placeholder="$t('label.top.plsinput')"
          prefix-icon="el-icon-search"
          @change="handleSearch"
          clearable="true"
          v-model="searchWord"
        >
        </el-input>
      </div>
      <div class="case-list-sort">
        <div
          v-show="activeSortOrderIcon === 0"
          class="case-list-sort-item"
          @click="handleSort('num', 1)"
        >
          <div class="sort-item-icon">
            <svg class="sort-item-icon-svg" aria-hidden="true">
              <use href="#icon-CaseNumber_up_def"></use>
            </svg>
          </div>
          <span>
            {{ $t("front-console-sort-field") }}
          </span>
        </div>
        <div
          v-show="activeSortOrderIcon === 1"
          class="case-list-sort-item"
          @click="handleSort('num', 2)"
        >
          <div class="sort-item-icon">
            <svg class="sort-item-icon-svg" aria-hidden="true">
              <use href="#icon-CaseNumber_cli"></use>
            </svg>
          </div>
          <span style="color: #006dcc">
            {{ $t("front-console-sort-field") }}
          </span>
        </div>
        <div
          v-show="activeSortOrderIcon === 2"
          class="case-list-sort-item"
          @click="handleSort('num', 1)"
        >
          <div class="sort-item-icon">
            <svg class="sort-item-icon-svg" aria-hidden="true">
              <use href="#icon-CaseNumber_up_cli"></use>
            </svg>
          </div>
          <span style="color: #006dcc">
            {{ $t("front-console-sort-field") }}
          </span>
        </div>
        <!-- 后续需求 -->
        <div
          class="case-list-sort-item"
          @click="handleSort('time')"
          v-show="false"
        >
          <div class="sort-item-icon">
            <svg
              v-show="activeSort === 'time'"
              class="sort-item-icon-svg"
              aria-hidden="true"
            >
              <use href="#icon-view_icon_sort_cli"></use>
            </svg>
            <svg
              v-show="activeSort !== 'time'"
              class="sort-item-icon-svg"
              aria-hidden="true"
            >
              <use href="#icon-view_icon_sort_def"></use>
            </svg>
          </div>
          <span v-show="activeSort !== 'time'">响应时间</span>
          <span v-show="activeSort === 'time'" style="color: #006dcc"
            >响应时间</span
          >
        </div>
      </div>
      <div v-loading="tableLoading" style="height: calc(100% - 150px)">
        <div class="case-list-box" ref="singleTable">
          <div class="common-flex" v-show="dataList.length === 0">
            <img src="./../assets/list-none.svg" alt="" />
          </div>
          <el-card
            :class="['box-card', { 'box-card-active': activeCase === item.id }]"
            v-for="(item, index) in dataList"
            :key="index"
            @click.native="caseDetail(item)"
          >
            <div class="box-card-header">
              <svg
                class="card-header-icon"
                v-show="item.generatecase === 'Recieve Email'"
                aria-hidden="true"
              >
                <use href="#icon-email_get"></use>
              </svg>
              <svg
                class="card-header-icon"
                v-show="item.generatecase === 'Sent Email'"
                aria-hidden="true"
              >
                <use href="#icon-email_send"></use>
              </svg>
              <svg
                class="card-header-icon"
                v-show="item.generatecase === 'Call in'"
                aria-hidden="true"
              >
                <use href="#icon-phone_get"></use>
              </svg>
              <svg
                class="card-header-icon"
                v-show="item.generatecase === 'Call out'"
                aria-hidden="true"
              >
                <use href="#icon-phone_send"></use>
              </svg>
              <svg
                class="card-header-icon"
                v-show="item.generatecase === 'Customer Feedback'"
                aria-hidden="true"
              >
                <use href="#icon-news_get"></use>
              </svg>
              <svg
                class="card-header-icon"
                v-show="item.generatecase === 'Conversation'"
                aria-hidden="true"
              >
                <use href="#icon-news_send"></use>
              </svg>
              <svg
                class="card-header-icon"
                v-show="item.generatecase === null"
                aria-hidden="true"
              >
                <use href="#icon-no_workOrder"></use>
              </svg>
              <!-- icon-email_send -->
              <!-- icon-phone_get -->
              <!-- icon-phone_send -->
              <!-- icon-news_get -->
              <!-- icon-news_send -->
              <span class="card-header-name">{{
                item.lxrxmccname ||
                item.visitorname ||
                $t("front-console-no-contact")
              }}</span>
            </div>
            <div class="box-card-center">
              <span class="card-center-time">{{
                item.duedate ||
                $t("vue_label_commonobjects_detail_activity_noexpirationdate")
              }}</span>
              <svg class="card-center-icon" aria-hidden="true">
                <use :href="'#icon-' + item.icon"></use>
              </svg>
              <!-- icon-notSet -->
              <!-- icon-expirationCompletion -->
              <!-- icon-notDue -->
              <!-- icon-unexpiredCompletion -->
            </div>
            <div class="box-card-footer">
              <!-- <span class="card-footer-order">{{item.num}}</span> -->
              <span class="card-footer-title">
                {{ "#" + item.name + " " }}{{ item.zhuti || "" }}
              </span>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetViewList, SetViewDefault } from "../api";
import * as controlPanelApi from "../api.js";

export default {
  data() {
    return {
      searchView: "",
      searchWord: "",
      // thumbtack: "thumbtack",
      // checkedthumbtack: "thumbtack1",
      // thumbtackImg: "thumbtack",
      activeCase: "", // 选中记录
      activeSort: "", // 当前排序
      activeSortOrderIcon: 0, // 按照个案编号排序图标
      activeSortOtherIcon: 0, // 按照其他字段排序图标
      viewId: "",
      viewDefaultId: "",
      viewName: "",
      viewInfo: {},
      viewLength: 0,
      viewList: [],
      viewDataList: [],
      objId: "201100000005024CxOSz",
      tableLoading: true,
      dataList: [],
      page: 1,
      pageSize: 20,
      sortField: "",
      sortBy: "desc",
      haveMore: "fasle",
      isNext: false,
    };
  },
  methods: {
    // 点击排序
    handleSort(type, index) {
      if (type === "num") {
        this.activeSortOrderIcon = index;
        this.activeSortOtherIcon = 0;
        if (index === 0) {
          this.sortField = "";
          this.activeSort = "";
          this.sortBy = "desc";
        } else if (index === 1) {
          this.activeSort = type;
          this.sortField = "name";
          this.sortBy = "asc";
        } else if (index === 2) {
          this.activeSort = type;
          this.sortField = "name";
          this.sortBy = "desc";
        }
      } else {
        // 其他字段排序，后续需求
        this.activeSortOrderIcon = 0;
        this.activeSortOtherIcon = index;
      }
      this.dataList = [];
      this.page = 1;
      this.getCaseList();
    },
    back() {
      this.$router.push({
        path: "/commonObjects/views/table/cloudcccase/201100000005024CxOSz/008?isConsole=true",
      });
    },
    caseDetail(item) {
      this.activeCase = item.id;
      this.$emit("checkCase", item);
    },
    // 表格搜索
    handleSearch() {
      this.dataList = [];
      this.page = 1;
      this.getCaseList();
    },
    // 打开视图下拉
    openViewBox() {
      this.searchView = "";
    },
    // 监听表格滚动
    scrollTable() {
      this.$refs.singleTable.addEventListener(
        "scroll",
        (res) => {
          let height = res.target;
          let clientHeight = height.clientHeight;
          let scrollTop = height.scrollTop;
          let scrollHeight = height.scrollHeight;
          // 滚动条总高度 减去 窗口高度 减去 已卷入的高度 小于 10px 就加载新数据
          if (scrollHeight - clientHeight - scrollTop < 10) {
            if (this.haveMore === "true" && this.isNext) {
              this.isNext === false;
              this.page++;
              this.getCaseList();
            }
          }
        },
        true
      );
    },

    handleTime(target) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      function handleHour(time) {
        let t = new Date(time);
        let hour = t.getHours();
        let min = addDateZero(t.getMinutes());
        let sec = addDateZero(t.getSeconds());
        if (hour < 12) {
          hour = addDateZero(hour);
          return hour + ":" + min + ":" + sec + " " + "AM";
        } else {
          hour -= 12;
          hour = addDateZero(hour);
          return hour + ":" + min + ":" + sec + " " + "PM";
        }
      }
      let targetTime = new Date(target);
      let day = 24 * 60 * 60 * 1000;
      let diffTime = targetTime - day;
      let newTarget = new Date(diffTime);
      let now = new Date();
      // 获取目标日期
      let tDate = addDateZero(targetTime.getDate());
      let tMonth = addDateZero(targetTime.getMonth() + 1);
      let tYear = targetTime.getFullYear();
      let date1 = tYear + "-" + tMonth + "-" + tDate;
      // 获取目标日期前一天
      let nDate = addDateZero(newTarget.getDate());
      let nMonth = addDateZero(newTarget.getMonth() + 1);
      let nYear = newTarget.getFullYear();
      let date2 = nYear + "-" + nMonth + "-" + nDate;
      // 获取当前日期
      let nowDate = addDateZero(now.getDate());
      let nowMonth = addDateZero(now.getMonth() + 1);
      let nowYear = now.getFullYear();
      let date3 = nowYear + "-" + nowMonth + "-" + nowDate;

      if (date1 === date3) {
        return this.$i18n.t("today") + " " + handleHour(target);
      } else if (date2 === date3) {
        return this.$i18n.t("tomorrow") + " " + handleHour(target);
      } else {
        return date1 + " " + handleHour(target);
      }
    },
    // 获取列表数据
    async getCaseList(flag) {
      if (flag == 1) {
        this.searchWord = this.$route.query.caseOrder;
        // this.sortField = "name"
        // this.activeSort = "num"
      }
      this.isNext = false;
      this.tableLoading = true;
      let conf = {
        obj: "008",
        viewId: this.viewId,
        pageNum: this.page,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDir: this.sortBy, // asc和desc
        searchKeyWord: this.searchWord,
        conditionValues: "",
        ischangenum: "false",
        recordnums: "",
        tagIds: "",
        isAllTag: "",
        campaignid: "false",
        isallsearch: "false",
        myself: "false",
        scrollId: "false",
        type: "",
        exactSearchFlag: "",
      };
      let res = await controlPanelApi.getCaseListData(conf);
      if (res.result) {
        let tempArr = res.data.list;
        tempArr &&
          tempArr.forEach((item) => {
            if (item.duedate) {
              item.duedate = this.handleTime(item.duedate);
            }
            item.icon = this.dateDiff(
              item.duedate,
              item.zhuangtai,
              item.closetime
            );
          });
        if (this.page === 1) {
          this.dataList = tempArr;
        } else if (this.page > 1) {
          this.dataList = [...this.dataList, ...tempArr];
        }
        // 更新dom
        this.haveMore = res.data.haveMore;
        this.tableLoading = false;
        this.$nextTick(() => {
          this.isNext = true;
        });
      }
    },
    dateDiff(target, status, close) {
      if (target !== null) {
        // 逾期图标：状态不等于已关闭或回访，
        if (status !== "关闭" || status !== "回访") {
          let t = new Date(target);
          let n = new Date();
          if (t < n) {
            // 到期日期<当前日期。
            return "Overdue";
          } else {
            // 到期日期>当前日期。
            return "notDue";
          }
        } else if (status === "关闭" || status === "回访") {
          let t = new Date(target);
          let c = new Date(close);
          if (t >= c) {
            // 到期日期>=关闭时间。
            return "expirationCompletion";
          } else {
            // 到期日期<关闭时间
            return "unexpiredCompletion";
          }
        }
      } else {
        return "notSet";
      }
    },
    // 设置默认视图
    async setDefaultView() {
      let data = {
        objId: this.objId,
        defaultViewId: this.viewId,
      };
      let res = await SetViewDefault(data);
      if (res.result === true) {
        this.viewDefaultId = this.viewId;
        this.$message({
          showClose: true,
          type: "success",
          //   设置默认视图成功
          message: this.$i18n.t("vue_label_commonobjects_view_successfully"),
        });
      } else {
        this.$message({
          showClose: true,
          type: "error",
          //   设置默认视图失败
          message: this.$i18n.t("vue_label_commonobjects_view_fail"),
        });
      }
    },
    // 更改视图
    changeView(item) {
      this.viewName = item.label;
      this.viewId = item.id;
      this.viewInfo = item;
      this.$refs.popoverRef.doClose();
      this.page = 1;
      this.searchWord = "";
      this.getCaseList();
      this.$emit("postView", this.viewId);
    },
    // 获取视图列表
    async getViewList() {
      this.viewName = "";
      let res = await GetViewList({
        objId: this.objId,
      });
      if (res.result) {
        this.viewList = res.data;
        this.viewDataList = res.data;
        this.viewLength = this.viewList.length;
        let arr = this.viewList.filter((item) => {
          return item.isdefault === "1";
        });
        this.viewList.map((item) => {
          if (item.id === this.$route.query.viewId) {
            this.viewName = item.label;
            this.viewId = item.id;
            this.viewInfo = item;
          }
        });
        if (arr.length === 0) {
          // this.viewName = this.viewList[0].label
          // this.viewId = this.viewList[0].id
          // this.viewInfo = this.viewList[0]
        } else if (arr.length === 1) {
          this.viewList.map(() => {
            // 获取默认视图信息
            // this.viewName = arr[0].label
            // this.viewId = arr[0].id
            this.viewDefaultId = arr[0].id;
            // this.viewInfo = arr[0]
          });
        }
        this.getCaseList(1);
      }
    },
  },
  mounted() {
    this.getViewList();
    this.scrollTable();
    this.activeCase = this.$route.query.caseId;
  },
  // 模糊搜索
  watch: {
    searchView(nval) {
      this.viewList = this.viewDataList.filter((val) => {
        return (
          val.label && val.label.toLowerCase().indexOf(nval.toLowerCase()) != -1
        );
      });
      if (nval == "") {
        this.viewList = this.viewDataList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.case-list {
  width: 272px;
  height: 100%;
  //   background: rgb(125, 137, 167);
  ::-webkit-scrollbar {
    display: none;
  }
  .case-list-header {
    width: 272px;
    height: 62px;
    padding: 0 20px;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.31);
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #080707;
    position: relative;
    .thumbtack {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      border-radius: 2px;
      text-align: center;
      display: inline-block;
      .tubiao {
        background: #fff;
        border: 1px solid #dedcda;
        border-radius: 3px;
        width: 18px;
        height: 18px;
        top: 0;
        svg {
          vertical-align: 0 !important;
          margin-left: 1px;
          margin-top: 1px;
          width: 13px;
        }
      }
    }
  }
  .case-list-content {
    height: calc(100% - 20px);
    .case-list-searchbox {
      // 40px
      padding: 16px 20px;
      ::v-deep .el-input__inner {
        border: none;
        border-bottom: 1px solid #dedcda;
      }
    }
    .case-list-sort {
      height: 36px;
      padding: 0 20px;
      background: #fafaf9;
      display: flex;
      align-items: center;
      // justify-content: space-around;
      .case-list-sort-item {
        width: 50%;
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        cursor: pointer;
        .sort-item-icon {
          width: 14px;
          height: 14px;
          display: flex;
          align-items: center;
          margin-right: 10px;
        }
        span {
          font-size: 14px;
          color: #333333;
          line-height: 19px;
        }
      }
    }
    .case-list-box {
      height: 100%;
      padding: 16px 20px;
      overflow: auto;
      .box-card {
        width: 232px;
        // height: 124px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
        // padding: 16px 20px;
        margin-bottom: 16px;
        cursor: pointer;
        .box-card-header {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          width: 192px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .card-header-icon {
            width: 20px;
            height: 20px;
            margin-right: 12px;
            flex-shrink: 0;
          }
          .card-header-name {
            font-size: 14px;
            color: #333333;
          }
        }
        .box-card-center {
          display: flex;
          align-items: center;
          padding-top: 5px;
          margin-bottom: 18px;
          .card-center-time {
            font-size: 12px;
            color: #666666;
            line-height: 16px;
            margin-right: 12px;
          }
          .card-center-icon {
            height: 16px;
            max-width: 24px;
          }
        }
        .box-card-footer {
          overflow: hidden;
          text-overflow: ellipsis;
          display: box;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          .card-footer-order {
            font-size: 12px;
            color: #333333;
            line-height: 16px;
            margin-right: 10px;
          }
          .card-footer-title {
            font-size: 14px;
            color: #333333;
            line-height: 16px;
          }
        }
      }
      .box-card-active {
        border-left: 4px solid #666666;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.39);
      }
    }
  }
}
.default-view {
  cursor: pointer;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.view-container {
  .view-search {
    ::v-deep .el-input__inner {
      border: none;
      border-bottom: 1px solid #dedcda;
    }
    margin-bottom: 10px;
  }
  .view-item-box {
    height: 300px;
    overflow-y: auto;
  }
  .view-item {
    cursor: pointer;
    width: 100%;
    height: 36px;
    padding: 8px 20px;
    font-size: 14px;
    color: #333333;
    line-height: 19px;
  }
  .view-item:hover {
    background: #f3f6f9;
  }
  .view-item-active {
    color: #006dcc;
    background: #f3f6f9;
  }
}
.common-flex {
  display: flex;
  justify-content: center;
}
::v-deep .el-card__body {
  padding: 16px;
}
</style>