<!--
 * @Author: your name
 * @Date: 2021-12-01 19:39:06
 * @LastEditTime: 2022-02-17 14:51:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\controlPanel\components\case-detail-konwlege.vue
-->
<template>
  <div>
    <div class="case-detail-left-knowlege-input">
      <!-- 中间全局搜索框 -->
      <div class="global-search">
        <!-- 右侧搜索框 -->
        <div class="right_search">
          <div class="searchFather">
            <!-- 搜索图标 -->
            <div class="searchIcon">
              <svg
                class="icon search_icon"
                aria-hidden="true"
                v-show="!checked"
              >
                <use href="#icon-search"></use>
              </svg>
            </div>
            <el-tag
              size="medium"
              closable
              style="margin-left: 30px"
              :key="tag"
              type="info"
              v-for="tag in dynamicTags"
              :disable-transitions="false"
              @close="handleClose(tag)"
              >{{ tag }}</el-tag
            >
            <input
              @keyup.enter="getResult"
              @focus="focus"
              @blur="blur"
              type="text"
              class="el-input"
              v-model="transferObj.userInput"
              :placeholder="$t('label.knowledgebase.search.knowledge.articles')"
            />
            <div
              style="cursor: pointer"
              class="el-icon-circle-close closeIcon"
              @click="clearInput"
              v-show="clearinput"
            ></div>
          </div>
        </div>
        <!-- 搜索结果页 -->
        <div class="searchResult" v-show="Adfiltering">
          <!-- 搜索结果 -->
          <div class="resultList">
            <el-popover
              placement="bottom"
              width="400"
              trigger="click"
              v-model="visible"
              :open-delay="500"
            >
              <div>
                <div style="display: flex; justify-content: space-between">
                  <h3 style="font-weight: 600">
                    {{ $t("label.knowledgebase.advanced.filtering") }}
                  </h3>
                  <h3
                    style="font-weight: 600; color: #006dcc"
                    @click="Resetfilter"
                  >
                    {{ $t("label.knowledgebase.reset.filter") }}
                  </h3>
                </div>
                <div style="margin-top: 20px">
                  <div>{{ $t("label.language") }}</div>
                  <el-select
                    v-model="langevalue"
                    :placeholder="$t('label.emailtocloudcc.select1')"
                    style="width: 370px"
                  >
                    <el-option
                      v-for="item in langeoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div style="margin-top: 20px">
                  <div>{{ $t("label.knowledgebase.release.status") }}</div>
                  <el-select
                    v-model="statevalue"
                    :placeholder="$t('label.emailtocloudcc.select1')"
                    style="width: 370px"
                  >
                    <el-option
                      v-for="item in stateoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div style="margin-top:20px；margin-bottom:20px">
                  <div>{{ $t("label.knowledgebase.verification.status") }}</div>
                  <el-select
                    v-model="textarea"
                    multiple
                    :placeholder="$t('label.emailtocloudcc.select1')"
                    style="width: 370px"
                  >
                    <el-option
                      v-for="item in verificationoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div style="text-align: right; margin-top: 20px">
                  <el-button size="mini" type="text" @click="applyClose">{{
                    $t("label.emailsync.button.cancel")
                  }}</el-button>
                  <el-button type="primary" size="mini" @click="applyClick">{{
                    $t("label.apply")
                  }}</el-button>
                </div>
              </div>
              <a
                slot="reference"
                style="margin-left: 20px; color: #006dcc; cursor: pointer"
                >{{ $t("label.knowledgebase.advanced.filtering") }}</a
              >
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="case-list-sort" v-if="resSign">
      <div class="case-list-sort-item">
        <!-- 推荐文章 -->
        <span
          :class="[
            'case-list-sort-item-unchecked',
            {
              'case-list-sort-item-checked': articleRecommended == false,
            },
          ]"
          @click="articleSelect('Recommended')"
          >{{ $t("label.knowledge.recommended.articles") }}</span
        >
      </div>
      <div class="case-list-sort-item">
        <!-- 个案文章 -->
        <span
          :class="[
            'case-list-sort-item-unchecked',
            {
              'case-list-sort-item-checked': articleCase == false,
            },
          ]"
          @click="articleSelect('Case')"
          >{{ $t("label.knowledge.case.article") }}</span
        >
      </div>
    </div>
    <div class="box-card" v-if="articleCaseList && articleCaseList.length > 0">
      <div v-for="(o, index) in articleCaseList" :key="index">
        <el-popover
          placement="right"
          width="450"
          style="height: 200px; overflow: auto"
          trigger="hover"
          :v-model="articlevisible"
          :ref="refNamePopover + o.id"
          @show="show(o)"
          @hide="hidePopver"
        >
          <div style="padding: 16px">
            <div>
              <div style="display: flex; justify-content: space-between">
                <h3>{{ o.name }}</h3>
              </div>

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  flex-wrap: wrap;
                "
              >
                <div style="width: 25%" v-for="item in labelarr" :key="item.id">
                  <p>{{ item.fieldLabel }}</p>
                  <p>{{ item.fieldValue }}</p>
                </div>
              </div>
              <div
                :style="{
                  height: dialogBodyHeight,
                  overflow: 'auto',
                }"
              >
                <info-form
                  ref="infoForm"
                  :objId="objids"
                  v-loading="loading"
                  :element-loading-text="$t('label.report.loading')"
                  :operation="operation"
                  :form-attr="formAttr"
                  :option-list="optionList"
                  :label-position="labelPosition"
                  :label-width="labelWidth"
                  :status-icon="statusIcon"
                  :objectApi="objectApi"
                ></info-form>
              </div>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
              "
            >
              <!-- 复制 -->
              <div style="margin-left: 10px">
                <el-button
                  type="primary"
                  size="mini"
                  @click="copyText(o.richtext ? o.richtext : '')"
                  ><span>{{
                    $t("pagecreator.page.button.copy")
                  }}</span></el-button
                >
              </div>
              <div>
                <div v-if="!o.caseArticle">
                  <el-button type="primary" size="mini" @click="Additional(o)"
                    ><span>{{
                      $t("label.knowledgebase.additional.articles")
                    }}</span></el-button
                  >
                </div>
                <div v-if="o.caseArticle">
                  <el-button type="primary" size="mini" @click="Additional(o)"
                    ><span>{{
                      $t("label.knowledgebase.cancel.attached.articles")
                    }}</span></el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div slot="reference">
            <div class="article-title" v-html="highLight1(o.name, keyword)">
              {{ o.name }}
            </div>
            <div class="article-content">
              {{ o.text ? o.text : o.summary ? o.summary : "" }}
            </div>
            <div class="knowlege-edit">
              <div
                class="knowlege-edit-left"
                @mouseover="handleIconviewAllStatus(index)"
                @mouseout="handleIconviewAllStatus(-1)"
              >
                <!-- 查看全部 -->
                <div
                  v-if="viewAllIndex == index"
                  @click="enterDetial(o.id)"
                  class="knowlege-edit-item"
                >
                  <svg class="aside-icon" aria-hidden="true">
                    <use href="#icon-knowledgeBase_viewAll_cli"></use></svg
                  ><span
                    :class="[
                      'see-all',
                      {
                        'see-all-active': viewAllIndex == index,
                      },
                    ]"
                    >{{ $t("component.newrecords.label.all") }}</span
                  >
                </div>
                <div
                  v-else
                  @click="enterDetial(o.id)"
                  class="knowlege-edit-item"
                >
                  <svg class="aside-icon" aria-hidden="true">
                    <use href="#icon-knowledgeBase_viewAll_def"></use></svg
                  ><span class="see-all">{{
                    $t("component.newrecords.label.all")
                  }}</span>
                </div>
              </div>
              <span class="copy">
                <!-- 复制 -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('pagecreator.page.button.copy')"
                  placement="bottom"
                >
                  <div
                    @mouseover="handleIconcopyCaseStatus(index)"
                    @mouseout="handleIconcopyCaseStatus(-1)"
                    @click="copyText(o.richtext ? o.richtext : '')"
                  >
                    <svg
                      class="aside-icon"
                      aria-hidden="true"
                      v-if="copyIndex == index"
                    >
                      <use href="#icon-knowledgeBase_copyCase_cli"></use>
                    </svg>
                    <svg v-else class="aside-icon" aria-hidden="true">
                      <use href="#icon-knowledgeBase_copyCase_def"></use>
                    </svg>
                  </div>
                </el-tooltip>
                <!-- 取消附加到个案、附加到个案 -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="
                    o.caseArticle
                      ? $t('label.knowledge.cancelattach.case')
                      : $t('label.knowledge.attach.case')
                  "
                  placement="bottom"
                >
                  <div
                    @mouseover="handleIconattachCaseStatus(index)"
                    @mouseout="handleIconattachCaseStatus(-1)"
                    @click="Additional(o)"
                  >
                    <svg
                      class="aside-icon addcase"
                      aria-hidden="true"
                      v-if="attachCaseIndex == index"
                    >
                      <use href="#icon-knowledgeBase_attachCase_cli"></use>
                    </svg>
                    <svg v-else class="aside-icon addcase" aria-hidden="true">
                      <use href="#icon-knowledgeBase_attachCase_def"></use>
                    </svg>
                  </div>
                </el-tooltip>
              </span>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div v-else class="knowledge-nodata">
      <img src="../assets/Nodata.png" class="knowledge-nodata-img" />
      <!-- 没有相关文章... -->
      <div class="knowledge-nodata-text">
        {{ $t("label.knowledge.No.related.articles") }}
      </div>
    </div>
  </div>
</template>
<script>
import { copyText } from "@/utils/copyInfo";
import * as SetViewApi from "../api";
import * as knowledgearticlesApi from "../api.js";
import { InfoForm } from "@/components/index";
import INPUTTYPE from "@/config/enumCode/inputType";

export default {
  props: {
    // caseData: {
    //   type: Object,
    // },
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    InfoForm,
  },
  data() {
    return {
      articleRecommended: false,
      articleCase: true,
      copyIndex: -1,
      attachCaseIndex: -1,
      viewAllIndex: -1,
      articleCaseList: [],
      objids: "cloudcc_project",
      loading: false,
      dialogBodyHeight: "400px",
      //保存所有的全局对象
      // globalobj: [],
      //选中的全局对象
      // selectobj: [],
      //搜索图标 关闭按钮的显示
      checked: false,
      clearinput: false,
      //结果页的显示
      showResult: false,

      // searchRecord: [],
      // isSearch: false,
      seaButton: false,
      //保存记录id
      // recordId: "",
      //向子组件传递对象
      transferObj: {
        searchObjVal: "all",
        //保存已选对象对应的id
        searchObjId: "all",
        //保存用户输入的搜索关键词
        userInput: "",
      },
      // loadDate: false,
      stateoptions: [
        {
          value: this.$i18n.t("label.knowledgebase.draft"),
          label: this.$i18n.t("label.knowledgebase.draft"),
        },
        {
          value: this.$i18n.t("label.archived"),
          label: this.$i18n.t("label.archived"),
        },
        {
          value: this.$i18n.t("label.knowledgebase.published"),
          label: this.$i18n.t("label.knowledgebase.published"),
        },
      ],
      langeoptions: [],
      statevalue: this.$i18n.t("label.knowledgebase.published"),
      langevalue: "",
      textarea: null,
      visible: false,
      articlevisible: false,
      // resultlistdata: [],
      // resultlistall: "",
      objectApi: "CloudccKArticle",
      // prefix: "k02",
      formAttr: null,
      operation: "DETAIL",
      labelPosition: "top",
      labelWidth: 180,
      statusIcon: true,
      attrIndex: -1, // 表单字段所在位置
      // personsName: "",
      optionList: {},
      // tabName: this.$i18n.t("label.knowledgebase.knowledge.article"),
      // filedialogVisible: false,
      // thumbsUpvalue: 0,
      // thumbsDownvalue: 0,
      refNamePopover: "popover-", // popover ref名称前缀
      // editdate: null,
      // draftdeldialogVisible: false, //草稿删除
      // articledeldialogVisible: false, //文章删除
      // releasedialogVisible: false,
      // recoverydialogVisible: false,
      // assigndialogVisible: false,
      // submittranslationdialogVisible: false,
      // transferOwnerDialog: false, //更改所有人
      // type: "changerecordtype",
      labelarr: [],
      keyword: "",
      // is_master_version: "", //是否为主版本
      conditional: "false", //主版本下是否有草稿
      // tableData: [],
      dynamicTags: [],
      verificationoptions: [
        {
          value: "Not Validated",
          label: "Not Validated",
        },
        {
          value: "Validated",
          label: "Validated",
        },
      ],
      articletype: "",
      Adfiltering: false,
      resSign: true,
    };
  },
  methods: {
    copyText,
    articleSelect(articleType) {
      this.articletype = articleType;
      if (articleType == "Recommended") {
        this.articleRecommended = false;
        this.articleCase = true;
        this.recommendedArticleQuery();
      } else {
        this.articleCase = false;
        this.articleRecommended = true;
        this.querCaseArticle();
      }
    },
    handleIconcopyCaseStatus(index) {
      this.copyIndex = index;
    },
    handleIconattachCaseStatus(index) {
      this.attachCaseIndex = index;
    },
    handleIconviewAllStatus(index) {
      this.viewAllIndex = index;
    },
    enterDetial(id) {
      this.$router.push(`/commonObjects/detail/${id}/DETAIL`);
    },
    //获取个案文章
    querCaseArticle() {
      let params = {
        caseId: this.id,
      };
      knowledgearticlesApi.querCaseArticle(params).then((res) => {
        this.articleCaseList = res.data;
      });
    },
    // 父组件里也调用了
    recommendedArticleQuery() {
      let params = {
        cloudccCaseId: this.id,
      };
      knowledgearticlesApi.recommendedArticleQuery(params).then((res) => {
        this.articleCaseList = res.data;
      });
    },
    // 附加/取消附加文章
    Additional(item) {
      let params = {
        articleId: item.id,
        caseId: this.id,
      };
      if (!item.caseArticle || item.addition == "") {
        knowledgearticlesApi.addCaseArticle(params).then(() => {
          item.caseArticle = true;
          item.addition = true;
        });
      } else {
        knowledgearticlesApi.cancelAttachCase(params).then(() => {
          if (this.articletype == "Case") {
            this.querCaseArticle();
          } else {
            item.caseArticle = false;
            item.addition = false;
          }
        });
      }
    },
    getFormAttr(id) {
      this.formAttr = [];
      knowledgearticlesApi.getDetail({
        id,
        objectApi: this.objectApi,
        operation: "DETAIL",
        recordType: "",
      }).then((res) => {
        // this.is_master_version = res.data.is_master_version;
        this.conditional = res.data.conditional;
        res.data.dataList.forEach((group) => {
          this.loading = false;
          // 每部分下标都从0开始
          if (group.showWhenEdit) {
            this.attrIndex = -1;
          }
          // 获取详情页用户对象跳转的用户名
          let colNum = group.colNum;
          // 部分名称展示与否通过showWhenEdit控制
          if (group.showWhenEdit) {
            this.formAttr.push({
              width: "100%",
              colNum: 1,
              type: "groupTitle",
              title: group.title,
              selectionId: group.selectionId,
              groupShow: true,
              hidden: false,
            });
          }
          // 非内嵌页面
          if (group.selectionType === "Field") {
            group.data &&
              group.data.length > 0 &&
              group.data.forEach((line) => {
                if (
                  line.left && !(Array.isArray(line.left) && line.left.length === 0) &&
                  line.left.fieldType !== "blank"
                ) {
                  let attr = this.formAttr.find((item) => {
                    return item.fieldId === line.left.fieldId;
                  });
                  if (attr === undefined) {
                    this.addFormAttr(
                      group.selectionId,
                      colNum,
                      line.left,
                      undefined,
                      "left"
                    );
                  }
                } else {
                  this.attrIndex += 1;
                  this.formAttr.push({
                    width: "50%",
                    colNum: 2,
                    attrIndex: this.attrIndex,
                    type: "noData",
                    hidden: false,
                    groupShow: true,
                    selectionId: group.selectionId,
                    position: 'left'
                  });
                }
                if (Number(colNum) !== 1) {
                  if (
                    !(Array.isArray(line.right) && line.right.length === 0) &&
                    line.right.fieldType !== "blank"
                  ) {
                    let attr = this.formAttr.find((item) => {
                      return item.fieldId === line.right.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr(
                        group.selectionId,
                        colNum,
                        line.right,
                        undefined,
                        "right"
                      );
                    }
                  } else {
                    this.attrIndex += 1;
                    this.formAttr.push({
                      width: "50%",
                      colNum: 2,
                      attrIndex: this.attrIndex,
                      type: "noData",
                      hidden: false,
                      groupShow: true,
                      selectionId: group.selectionId,
                    });
                  }
                }
              });
          } else {
            // 内嵌页面
            this.formAttr.push({
              width: "100%",
              colNum: 1,
              type: "customPage",
              pageUrl: group.pageUrl,
              selectionId: group.selectionId,
              groupShow: true,
              hidden: false,
            });
          }
        });
      });
    },
    // 查找带值字段
    // setLookupValue(lookupValueExpression) {
    //   this.lookupValues = [];
    //   lookupValueExpression.forEach((item) => {
    //     this.formAttr.forEach((attr) => {
    //       if (attr.apiname === item.expression.split(",")[0]) {
    //         attr.changeEvent =
    //           attr.changeEvent === undefined
    //             ? "lookupValue"
    //             : `${attr.changeEvent},lookupValue`;
    //         // 初始化规则时走规则
    //         if (attr.value !== undefined) {
    //           // 新建时必须修改
    //           // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
    //           this.getLookupValue(item, attr.value);
    //         }
    //       }
    //     });
    //     this.lookupValues.push(item);
    //   });
    // },
    // // 查找带值
    // getLookupValue(lookupRule, id, formData) {
    //   // id: 查找记录的id
    //   let fieldJson = [];
    //   let carryValue = "";

    //   fieldJson.push({
    //     fieldrelateid: lookupRule.id,
    //     id: id,
    //     expression: lookupRule.expression,
    //     iscover: lookupRule.iscover,
    //   });
    //   let param = {
    //     objId: this.prefix,
    //     fieldJson: JSON.stringify(fieldJson),
    //   };
    //   knowledgearticlesApi.getLookupRelatedFieldValue(param).then((res) => {
    //     carryValue = res.data && res.data.length > 0 ? res.data[0].value : null;
    //     this.formAttr.forEach((attr) => {
    //       if (attr.fieldId === lookupRule.id) {
    //         if (formData !== undefined) {
    //           // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
    //           if (
    //             attr.value === "" ||
    //             attr.value === null ||
    //             (Array.isArray(attr.value) && attr.value.length === 0) ||
    //             lookupRule.iscover === "true"
    //           ) {
    //             // 查找、查找多选字段特殊处理
    //             if (
    //               (attr.fieldType === "Y" || attr.fieldType === "MR") &&
    //               res.data[0].value &&
    //               res.data[0].lkid &&
    //               res.data[0].value !== "" &&
    //               res.data[0].lkid !== ""
    //             ) {
    //               this.optionList[attr.prop] = [
    //                 {
    //                   value: res.data[0].lkid,
    //                   label: res.data[0].value,
    //                 },
    //               ];
    //               if (res.data[0].lkid !== "") {
    //                 attr.value =
    //                   attr.fieldType === "Y"
    //                     ? res.data[0].lkid
    //                     : [res.data[0].lkid];
    //               }
    //             } else {
    //               attr.value = carryValue;
    //             }
    //           }
    //         } else {
    //           // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
    //           if (
    //             formData[attr.prop] === "" ||
    //             formData[attr.prop] === null ||
    //             (Array.isArray(formData[attr.prop]) &&
    //               formData[attr.prop].length === 0) ||
    //             lookupRule.iscover === "true"
    //           ) {
    //             // 查找、查找多选字段特殊处理
    //             if (
    //               (attr.fieldType === "Y" || attr.fieldType === "MR") &&
    //               res.data[0].value &&
    //               res.data[0].lkid &&
    //               res.data[0].value !== "" &&
    //               res.data[0].lkid !== ""
    //             ) {
    //               this.optionList[attr.prop] = [
    //                 {
    //                   value: res.data[0].lkid,
    //                   label: res.data[0].value,
    //                 },
    //               ];
    //               if (res.data[0].lkid !== "") {
    //                 formData[attr.prop] =
    //                   attr.fieldType === "Y"
    //                     ? res.data[0].lkid
    //                     : [res.data[0].lkid];
    //               }
    //             } else {
    //               formData[attr.prop] = carryValue;
    //             }
    //           }
    //         }
    //       }
    //     });
    //   });
    // },
    // // 改变查找/查找带值选项及值
    // changeSelect(row, fieldId, formData) {
    //   let options = [];
    //   let fieldVal = [];
    //   if (row instanceof Array) {
    //     row.forEach((ele) => {
    //       options.push({
    //         label: ele.name,
    //         value: ele.id,
    //       });
    //     });
    //   } else {
    //     options.push({
    //       label: row.data.name,
    //       value: row.data.id,
    //     });
    //   }

    //   this.formAttr.forEach((attr) => {
    //     if (attr.fieldId === fieldId) {
    //       // 防止清空时清空选项，再次编辑查找字段时显示数据Id
    //       if (options.length > 0) {
    //         this.$set(this.optionList, attr.prop, options);
    //       }
    //       if (attr.fieldType === "MR") {
    //         options.forEach((option) => {
    //           fieldVal.push(option.value);
    //         });
    //         formData[attr.apiname] = fieldVal;
    //       } else {
    //         formData[attr.apiname] = row.data === undefined ? "" : row.data.id;
    //       }

    //       // 若有查找带值，将值带入
    //       this.lookupValues.forEach((lookupValue) => {
    //         if (attr.apiname === lookupValue.expression.split(",")[0]) {
    //           this.getLookupValue(lookupValue, optionValue, formData);
    //         }
    //       });
    //     }
    //   });
    // },
    // 表单添加属性
    addFormAttr(selectionId, colNum, attr, isChildren, position) {
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      this.$set(attr, "selectionId", selectionId);
      if (attr.required && attr.edit) {
        attr.rules = [
          {
            required: attr.required,
            message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
              attr.fieldLabel || attr.label
            }`,
            trigger: "blur",
          },
        ];
      }

      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      if (attr.fieldType !== "AD" && attr.fieldType !== "LT") {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision = Number(attr.decimalPlaces);
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        }
        // 图片显示
        if (
          attr.fieldType === "IMG" &&
          attr.expressionType !== "url" &&
          attr.value !== undefined &&
          this.operation === "EDIT"
        ) {
          if (attr.value !== "") {
            attr.data = [
              {
                name: "",
                url: `${this.userUrl}/querysome.action?m=viewImg&fileid=${attr.value}&binding=${this.token}`,
              },
            ];
          } else {
            attr.data = [];
          }
        }
        // 评分+1
        if (attr.type === "score") {
          attr.value =
            this.operation === "EDIT" ? Number(attr.value) : Number(attr.value);
        }
        if (attr.type === "select" || attr.type === "multi-select") {
          let options = [];
          attr.data &&
            attr.data.length > 0 &&
            attr.data.forEach((item) => {
              options.push({
                key: item.value,
                val: item.id,
              });
            });

          this.optionList[attr.prop] = options;
          // 记录类型选项特殊处理
          if (attr.prop === "recordtype") {
            this.recordType = attr.id;
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }
          // else {
          //   this.recordType = attr.value;
          //   // this.optionList[attr.prop] = this.recordOptions;
          // }

          if (attr.type === "multi-select") {
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.value === ""
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (
            attr.fieldType === "MR" &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.data[0].value === ""
                ? []
                : attr.value.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            // 查找字段对应的记录有对应的value时才展示
            if (
              attr.value !== "" &&
              attr.data &&
              attr.data.length > 0 &&
              attr.data[0].value !== ""
            ) {
              multiOptions.push({
                label: attr.data[0].value,
                value: attr.data[0].id,
              });
            } else {
              attr.value = "";
            }
            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }
          attr.readonly = true;
        } else if (attr.type === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.type === "checkbox") {
          attr.value = attr.value === "true" ? true : false;
        }
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.colNum = colNum;
        attr.selectionId = selectionId;
        attr.groupShow = true;
        if (
          this.operation === "DETAIL" ||
          (this.operation === "EDIT" && attr.apiname !== "cloudcctag")
        ) {
          this.formAttr.push(attr);
        }
      } else if (this.operation === "DETAIL") {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.selectionId = selectionId;
        attr.groupShow = true;
        this.formAttr.push(attr);
      } else {
        // 地址和地理定位特殊处理
        attr.children.forEach((item) => {
          this.addFormAttr(selectionId, colNum, item, true);
          // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
          if (item["name"].slice(item["name"].length - 2) === "00") {
            this.getCountrySelectValue(item["name"]);
          }
        });

        this.attrIndex -= attr.children.length - 1;
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
    },
    highLight1(val, keyword) {
      let Reg = new RegExp(keyword, "g");
      if (val) {
        const res = val.replace(
          Reg,
          `<span style="background:yellow;">${keyword}</span>`
        );
        return res;
      }
    },
    //查询结果悬浮
    show(item) {
      this.articlevisible = true;
      this.loading = true;
      this.getBrief(item.id);
      this.getFormAttr(item.id);
    },
    //获取标签
    getBrief(id) {
      SetViewApi.GetBrief({ id }).then((res) => {
        this.labelarr = res.data.heigthLight;
      });
    },
    Resetfilter() {
      this.queryLanguage();
      this.textarea = null;
      this.statevalue = this.$i18n.t("label.knowledgebase.published");
    },
    // 查询语言接口
    async queryLanguage() {
      this.langeoptions = [];
      let params = {
        id: "",
      };
      knowledgearticlesApi.queryLanguage(params).then((res) => {
        for (let i in res.data.languageList) {
          this.langeoptions.push({
            value: res.data.languageList[i].language,
            label: res.data.languageList[i].language,
          });
          if (res.data.languageList[i].is_default_language == "true") {
            this.langevalue = res.data.languageList[i].language;
          }
        }
      });
    },
    // 高级筛选点击应用
    applyClick() {
      this.visible = false;
      this.Adfiltering = false;
      if (this.textarea && this.textarea.length > 0) {
        this.dynamicTags = [
          this.$i18n.t("label.knowledgebase.filters", { a: "3" }),
        ];
      } else {
        this.dynamicTags = [
          this.$i18n.t("label.knowledgebase.filters", { a: "2" }),
        ];
      }
    },
    applyClose() {
      this.visible = false;
      this.clearinput = false;
    },
    // 标签隐藏
    handleClose(tag) {
      this.queryLanguage();
      this.textarea = null;
      this.statevalue = this.$i18n.t("label.knowledgebase.published");
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    focus() {
      this.Adfiltering = true;
      this.clearinput = true;
    },
    blur() {
      setTimeout(() => {
        this.Adfiltering = false;
      }, 400);
      if (this.transferObj.userInput != "") {
        this.clearinput = true;
      }
    },
    clearInput() {
      this.transferObj.userInput = "";
      this.clearinput = false;
      this.resSign = true;
      this.articleSelect("Recommended");
    },
    getResult() {
      //输入框内有无值判断
      if (this.transferObj.userInput == "") {
        // this.$message({
        //   showClose: true,
        //   type: "warning",
        //   message: this.$i18n.t("label.globalsearch.tip"),
        // });
        this.Adfiltering = false;
        this.resSign = true;
        this.articleSelect("Recommended");
        return false;
      }
      this.checked = false;
      this.seaButton = false;
      // this.clearinput = false;
      this.showResult = false;
      this.Adfiltering = false;
      this.getSingleGlobalResult();
    },
    //获取单个对象搜索结果
    async getSingleGlobalResult(val = "") {
      let obj = {
        conditions: "",
        objId: "cloudcc_k_article",
        page: "1",
        pageSize: "10",
        searchKeyWord: this.transferObj.userInput || val,
        dir: "",
        sort: "",
        language: this.langevalue,
        issueStatus: this.statevalue,
        verifyStatus:
          this.textarea && this.textarea.length > 0
            ? this.textarea.toString()
            : "",
        caseArticleId: this.id,
      };
      this.keyword = obj.searchKeyWord;
      knowledgearticlesApi.getGlobalSearchSingleResult(obj).then((res) => {
        // if(res.data.dataList.length==0){
        //   this.articleSelect("Recommended")
        // }else{
        this.resSign = false;
        this.articleCaseList = res.data.dataList;
        // }
        // this.transferObj.userInput = "";
      });
    },
  },
  mounted() {
    this.queryLanguage();
  },
};
</script>
<style lang="scss" scoped>
.case-detail {
  height: 100%;
  width: calc(100% - 272px);
  display: flex;
  position: relative;
  box-shadow: -4px 0px 8px 0px rgba(0, 0, 0, 0.3);
  .case-detail-left {
    width: 388px;
    background: #ffffff;
    box-shadow: -4px 0px 8px 0px rgba(0, 0, 0, 0.3);
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }

    .case-detail-left-contact {
      &-box {
        padding: 20px 40px 0 40px;
        .contact-box-card {
          .contact-box-card-header {
            border-bottom: 1px dashed #9e9e9e;
            .card-header-linkman {
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              span:first-child {
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                line-height: 21px;
                margin-right: 4px;
              }
              span:nth-child(2) {
                margin-right: 4px;
                color: #999999;
                line-height: 16px;
              }
              .header-linkman-type-box {
                cursor: pointer;
                display: flex;
                align-items: center;
                .card-header-linkman-type {
                  font-size: 12px;
                  color: #999999;
                  line-height: 16px;
                }
                .linkman-icon {
                  margin-right: 8px;
                  height: 20px;
                  width: 20px;
                  cursor: pointer;
                }
              }
              .header-linkman-type-box:hover {
                .card-header-linkman-type {
                  color: #006dcc;
                }
              }
            }
            .card-header-client {
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              &-name {
                font-size: 14px;
                color: #333333;
                line-height: 19px;
                max-width: 108px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &-icon1 {
                width: 16px;
                height: 16px;
                margin-right: 4px;
              }
              span {
                font-size: 14px;
                color: #999999;
                line-height: 19px;
              }
              .header-client-type-box {
                cursor: pointer;
                display: flex;
                align-items: center;
                .card-header-client-icon2 {
                  width: 20px;
                  height: 20px;
                  margin: 0 4px;
                }
                span {
                  cursor: pointer;
                  font-size: 12px;
                  color: #333333;
                  // color: #006dcc;
                  line-height: 16px;
                }
              }
              .header-client-type-box:hover {
                span {
                  color: #006dcc;
                }
              }
            }
            .card-header-email {
              margin-bottom: 8px;
              font-size: 14px;
              color: #333333;
              line-height: 19px;
            }
            .card-header-phone {
              margin-bottom: 16px;
              font-size: 14px;
              color: #333333;
              line-height: 19px;
              display: flex;
              align-items: center;
              span {
                margin-right: 8px;
              }
              &-icon {
                width: 14px;
                height: 14px;
                cursor: pointer;
              }
            }
            .card-header-website {
              margin-bottom: 14px;
              font-size: 14px;
              color: #333333;
              line-height: 19px;
            }
          }
          .contact-box-card-container {
          }
          .contact-box-card-footer {
          }
        }
      }
      .detail-left-bottom {
        width: 388px;
        height: 44px;
        background: #ffffff;
        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.32);
        // width: 300px;
        position: absolute;
        bottom: 0;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
      }
    }
    .case-detail-left-knowlege {
      // padding: 20px 40px 0 40px;
      .box-card {
        margin: 0 40px 0 40px;
        border-bottom: 1px solid #e5e5e5;
      }
      .knowlege-edit {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        padding-bottom: 20px;
        .knowlege-edit-item {
          display: flex;
        }
        .copy {
          display: flex;
          align-content: center;
        }
        .addcase {
          padding-left: 8px;
        }
        .see-all {
          font-size: 12px;
          line-height: 16px;
          color: #999999;
          padding-left: 8px;
        }
        .see-all-active {
          color: #2d6cfc;
        }
      }
      .case-detail-left-knowlege-input {
        padding: 20px 40px;
        ::v-deep .el-input {
          height: 36px;
        }
      }
      .article-title {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 19px;
        padding: 24px 0 8px 0;
      }
      .article-content {
        font-size: 12px;
        color: #666666;
        line-height: 20px;
        padding-bottom: 16px;
      }
    }
    .case-detail-left-history {
      &-box {
        padding: 20px 40px 0 40px;
      }
      .history-btn-box {
        width: 388px;
        height: 44px;
        background: #ffffff;
        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.32);
        // width: 300px;
        position: absolute;
        bottom: 0;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
      }
    }
  }
  .case-detail-right {
    position: relative;
    width: calc(100% - 388px);
    .status-bar {
      position: absolute;
      top: -50px;
      right: 40px;
      transform: rotate(-45deg);
      width: 40px;
      height: 220px;
      padding: 10px;
      background: green;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      &-text {
        transform: rotate(90deg);
        white-space: nowrap;
        color: #fff;
      }
    }
  }
  .fold-content {
    position: absolute;
    left: -12px;
    top: 40%;
    width: 12px;
    height: 84px;
    background: #999999;
    border-radius: 4px 0px 0px 4px;
    .fold-content-icon {
      width: 10px;
      height: 10px;
    }
  }
  .edit-line {
    position: absolute;
    top: 160px;
    left: -36px;
    border-right: none;
    font-size: 24px;
    box-shadow: -4px 0px 8px 0px rgba(0, 0, 0, 0.3);
    border-radius: 8px 0px 0px 8px;
    .active-item-contact {
      width: 36px;
      height: 40px;
      background: #ffffff;
      border-radius: 8px 0px 0px 0px;
    }
    .active-item-contact-active {
      width: 36px;
      height: 40px;
      background: linear-gradient(135deg, #666666 0%, #2e2e2e 100%);
      border-radius: 8px 0px 0px 0px;
    }
    .active-item-knowlege {
      width: 36px;
      height: 40px;
      background: #ffffff;
    }
    .active-item-knowlege-active {
      width: 36px;
      height: 40px;
      background: linear-gradient(45deg, #666666 0%, #2e2e2e 100%);
    }
    .active-item-history {
      width: 36px;
      height: 40px;
      background: #ffffff;
      border-radius: 0px 0px 0px 8px;
    }
    .active-item-history-active {
      width: 36px;
      height: 40px;
      background: linear-gradient(135deg, #666666 0%, #2e2e2e 100%);
      border-radius: 0px 0px 0px 8px;
    }
    .active-item-active {
      background: #000;
    }
  }
  // .edit-line-active {
  //   left: 0;
  // }
}
/*表格全选框去除空框*/
::v-deep .el-table th > .cell {
  display: none !important;
  //   position: relative;
}
.aside-icon {
  width: 16px;
  height: 16px;
}
.common-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.case-list-sort {
  // height: 36px;
  padding: 0 20px;
  background: #fafaf9;
  display: flex;
  align-items: center;
  // justify-content: space-around;
  .case-list-sort-item {
    width: 50%;
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    .case-list-sort-item-unchecked {
      font-size: 14px;
      color: #666666;
      line-height: 19px;
      padding: 10px 0;
    }
    .case-list-sort-item-checked {
      color: #000;
      border-bottom: 1px solid #000;
    }
  }
}
.knowledge-nodata {
  text-align: center;
  .knowledge-nodata-img {
    padding: 20px 0 22px 0;
  }
  .knowledge-nodata-text {
    font-size: 12px;
    color: #666666;
    line-height: 16px;
  }
}
.searchFather {
  position: relative;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.global-search {
  width: 100%;
}
.right_search {
  display: flex;
  align-content: center;
}
.searchIcon {
  position: absolute;
  left: 7px;
  top: 10px;
}
.search_icon {
  width: 15px;
  height: 15px;
}
.el-input {
  width: 40%;
  border: none;
  height: 25px;
  padding-left: 30px;
  // padding-right: 80px;
  font-size: 12px;
  background: transparent;
  border-radius: 0px;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.searchResult {
  // width: 90%;
  min-height: 30px;
  background-color: white;
  box-shadow: 0px 2px 10px 2px #efefef;
  margin-top: 0px;
  margin-left: 0px;
  z-index: 22222;
  overflow: hidden;
  font-size: 14px;
}
.resultList {
  // padding: 15px;
  padding: 2px 0px;
  overflow: hidden;
}
.closeIcon {
  position: absolute;
  right: 8px;
  top: 12px;
  // z-index: 999;
}
</style>

