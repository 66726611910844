<template>
  <div class="container">
    <div class="header">
      <i
        class="el-icon-back header-icon"
        style="font-size: 20px"
        @click="goBackCase"
      ></i>
      <span v-show="object === 'contact'" class="header-title">
        {{ $t("label.datamanage.contact.information") }}
      </span>
      <span v-show="object === 'client'" class="header-title">
        {{ $t("label.datamanage.account.information") }}
      </span>
      <svg class="header-icon" aria-hidden="true" @click="newTabDetail">
        <use href="#icon-share"></use>
      </svg>
    </div>
    <div class="blank-space"></div>
    <div class="content-box">
      <div class="center">
        <div v-show="object === 'contact'" class="center-contact">
          <div class="contact-box-card-header">
            <div class="card-header-linkman">
              <span>{{
                contactInfo.name || $t("front-console-no-contact")
              }}</span>
            </div>
            <div
              class="card-header-client"
              v-show="contactInfo.accountid != null"
            >
              <div class="card-header-client-name">
                {{ contactInfo.khmc }}
              </div>
              <span
                v-show="contactInfo.satisfactionevaluation != null"
                style="margin: 0 4px"
                >·</span
              >
              <svg
                v-show="
                  contactInfo.satisfactionevaluation != null &&
                  contactInfo.satisfactionevaluation > 50
                "
                class="card-header-client-icon1"
                aria-hidden="true"
              >
                <use href="#icon-assess_facce1_good"></use>
              </svg>
              <svg
                v-show="
                  contactInfo.satisfactionevaluation != null &&
                  contactInfo.satisfactionevaluation <= 50
                "
                class="card-header-client-icon1"
                aria-hidden="true"
              >
                <use href="#icon-assess_face1_bad"></use>
              </svg>
              <span v-show="contactInfo.satisfactionevaluation != null"
                >{{ contactInfo.satisfactionevaluation }}%</span
              >
            </div>
            <div class="card-header-email">
              {{ contactInfo.email }}
            </div>
            <div
              class="card-header-phone"
              v-show="contactInfo.shouji != null || contactInfo.dianhua != null"
            >
              <span>
                {{ contactInfo.shouji || contactInfo.dianhua }}
              </span>
              <svg class="card-header-phone-icon" aria-hidden="true">
                <use href="#icon-Call"></use>
              </svg>
            </div>
            <!-- <div class="card-header-website">https:www.cloudcc.com</div> -->
          </div>
        </div>
        <div v-show="object === 'client'" class="center-client">
          <div class="client-icon">
            <svg class="client-icon" aria-hidden="true">
              <use href="#icon-console_case_clientLoge"></use>
            </svg>
          </div>
          <div class="client-name">{{ contactInfo.khmc }}</div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-tab">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            @click="swtichTab(item)"
            :class="[
              activeTab == item.value ? 'tab-item tab-item-active' : 'tab-item',
            ]"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="footer-content">
          <div v-show="activeTab === 'info'" class="asset-box">
            <project360
              ref="project360"
              id="listChild"
              showPart="detail"
              :dataId="id"
              :objectApi="objectApi"
              :prefix="prefix"
              :isEditDetail="isEdit"
              :isQueryDetail="isQuery"
              :locked="isLocked"
              :canEditOwner="isCanEditOwner"
              detailType="controlPanel"
              @refreshAll="getCradDetail"
            ></project360>
          </div>
          <div v-show="activeTab === 'asset'" class="asset-box">
            <div class="asset-item">
              <svg class="header-icon" aria-hidden="true">
                <use href="#icon-share"></use>
              </svg>
              <div class="content-item">
                <div class="content-item-label">客户名称</div>
                <div class="content-item-value">123</div>
              </div>
            </div>
          </div>
          <div v-show="activeTab === 'order'" class="asset-box">
            <div class="asset-item">
              <svg class="header-icon" aria-hidden="true">
                <use href="#icon-share"></use>
              </svg>
              <div class="content-item">
                <div class="content-item-label">客户名称</div>
                <div class="content-item-value">123</div>
              </div>
            </div>
          </div>
          <div v-show="activeTab === 'workorder'" class="asset-box">
            <div class="asset-item">
              <svg class="header-icon" aria-hidden="true">
                <use href="#icon-share"></use>
              </svg>
              <div class="content-item">
                <div class="content-item-label">客户名称</div>
                <div class="content-item-value">123</div>
              </div>
            </div>
          </div>
          <div v-show="activeTab === 'evaluation'" class="asset-box">评价</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import project360 from "@/views/commonObjects/project-detail/project360";
import { getPermissionById } from "../api";

export default {
  name: "detail-card",
  components: {
    project360,
  },
  data() {
    return {
      id: "",
      objId: "",
      objectApi: "",
      prefix: "",
      isQuery: false, // 记录可查权限
      isCanEditOwner: false, //判断是否有更改所以人权限
      isLocked: false, //是否锁定
      isEdit: false, // 编辑权限

      contactInfo: {},
      object: "contact",
      activeTab: "info",
      tabList: [
        {
          label: this.$i18n.t("label.info"),
          // label: this.$i18n.t("label.asset"),
          value: "info",
        },
        // {
        //     label: "资产",
        //     value: "asset",
        // },
        // {
        //     label: "工单",
        //     value: "order",
        // },
        // {
        //     label: "派工单",
        //     value: "workorder",
        // },
        // {
        //     label: "评价",
        //     value: "evaluation",
        // },
      ],
    };
  },
  methods: {
    // 获取联系人或客户的详细信息
    getCradDetail() {
      getPermissionById({ id: this.id }).then((res) => {
        if (res.returnCode === "1" && res.result) {
          this.objectApi = res.data.objectApi;
          this.prefix = res.data.prefix;
          this.isEdit = res.data.isEdit;
          this.objId = res.data.objId;
          this.isLocked = res.data.isLocked;
          this.isCanEditOwner = res.data.canEditOwner;
          this.isQuery = res.data.isQuery;
          this.$nextTick(() => {
            this.$refs.project360.init(this.id);
          });
        }
      });
    },
    goBackCase() {
      this.$emit("closeCard");
    },
    swtichTab(item) {
      if (this.activeTab !== item.value) {
        this.activeTab = item.value;
      }
    },
    newTabDetail() {
      const newPage = this.$router.resolve({
        path: `/commonObjects/detail/${this.id}/DETAIL`,
      });
      window.open(newPage.href, "_blank");
    },
    /**
     * 父组件中ref调用
     */
    render(conf) {
      this.contactInfo = conf;
      if (this.object === "contact") {
        //   联系人信息
        this.id = conf.id;
        this.objId = "contact";
        this.objectApi = "Contact";
        this.prefix = "003";
      } else if (this.object === "client") {
        //   客户信息
        this.id = conf.accountid;
        this.objId = "account";
        this.objectApi = "Account";
        this.prefix = "001";
      }
      this.getCradDetail();
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.container {
  // position: relative;
  ::v-deep .el-icon-back {
    color: #888;
  }
  .header {
    height: 62px;
    width: 388px;
    position: absolute;
    top: 0;
    background: #fafaf9;
    border: 1px solid #dedcda;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    .header-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      font-size: 16px;
    }
    .header-title {
      font-size: 16px;
      color: #080707;
      line-height: 21px;
    }
  }
  .blank-space {
    height: 62px;
  }
  .content-box {
    height: calc(100% - 62px);
    overflow: auto;
    .center {
      padding: 20px 40px 20px 40px;
      .center-contact {
        .contact-box-card-header {
          // border-bottom: 1px dashed #9e9e9e;
          .card-header-linkman {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            span:first-child {
              font-size: 16px;
              font-weight: bold;
              color: #333333;
              line-height: 21px;
              margin-right: 4px;
            }
            span:nth-child(2) {
              margin-right: 4px;
              color: #999999;
              line-height: 16px;
            }
            .header-linkman-type-box {
              cursor: pointer;
              display: flex;
              align-items: center;
              .card-header-linkman-type {
                font-size: 12px;
                color: #999999;
                line-height: 16px;
              }
              .linkman-icon {
                margin-right: 8px;
                height: 20px;
                width: 20px;
                cursor: pointer;
              }
            }
            .header-linkman-type-box:hover {
              .card-header-linkman-type {
                color: #006dcc;
              }
            }
          }
          .card-header-client {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            &-name {
              font-size: 14px;
              color: #333333;
              line-height: 19px;
              max-width: 108px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &-icon1 {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            span {
              font-size: 14px;
              color: #999999;
              line-height: 19px;
            }
            .header-client-type-box {
              cursor: pointer;
              display: flex;
              align-items: center;
              .card-header-client-icon2 {
                width: 20px;
                height: 20px;
                margin: 0 4px;
              }
              span {
                cursor: pointer;
                font-size: 12px;
                color: #333333;
                // color: #006dcc;
                line-height: 16px;
              }
            }
            .header-client-type-box:hover {
              span {
                color: #006dcc;
              }
            }
          }
          .card-header-email {
            margin-bottom: 8px;
            font-size: 14px;
            color: #333333;
            line-height: 19px;
          }
          .card-header-phone {
            margin-bottom: 16px;
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            display: flex;
            align-items: center;
            span {
              margin-right: 8px;
            }
            &-icon {
              width: 14px;
              height: 14px;
              cursor: pointer;
            }
          }
          .card-header-website {
            font-size: 14px;
            color: #333333;
            line-height: 19px;
          }
        }
      }
      .center-client {
        display: flex;
        align-items: center;
        .client-icon {
          width: 48px;
          height: 48px;
          margin-right: 16px;
        }
        .client-name {
          font-size: 20px;
          font-weight: bold;
          color: #080707;
          line-height: 27px;
        }
      }
    }
    .footer {
      .footer-tab {
        width: 100%;
        height: 36px;
        background: #fafaf9;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding: 0px 40px;
        .tab-item {
          font-size: 14px;
          color: #666666;
          margin-right: 30px;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .tab-item-active {
          font-weight: bold;
          color: #333333;
          border-bottom: 1px solid;
        }
      }
      .footer-content {
        .asset-box {
          padding: 20px 40px;
          .asset-item {
            width: 308px;
            // height: 146px;
            background: #ffffff;
            box-shadow: 0px 0px 12px 0px rgba(0, 12, 24, 0.3);
            border-radius: 4px;
            position: relative;
            .header-icon {
              cursor: pointer;
              height: 16px;
              width: 16px;
              position: absolute;
              right: 20px;
              top: 18px;
            }
            .content-item {
              display: flex;
              align-items: center;
              margin-bottom: 16px;
              padding: 18px 20px;
              &-label {
                width: 84px;
                height: 16px;
                font-size: 12px;
                color: #666666;
                line-height: 16px;
              }
              #-value {
                height: 20px;
                font-size: 14px;
                font-weight: bold;
                color: #333333;
                line-height: 19px;
              }
            }
          }
        }
      }
    }
  }
}
</style>