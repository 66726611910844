<template>
  <!-- 个案历史纪录 -->
  <div class="case-line" style="padding-bottom: 50px" v-loading="lineLoading">
    <div class="case-line-box" v-for="(item, index) in caseList" :key="index">
      <div class="case-line-box-day" v-show="item.name !== item.id">
        {{ item.name }}
      </div>
      <div class="case-line-box-date">
        {{ item.id }}
      </div>
      <div
        class="case-line-item"
        v-for="(itemCase, indexCase) in item.data"
        :key="indexCase"
      >
        <div class="case-line-item-check">
          <el-checkbox
            @change="changeChecked(itemCase, index, indexCase)"
            v-model="itemCase.checked"
          ></el-checkbox>
        </div>
        <div class="case-line-item-info">
          <div class="case-line-item-info-title">
            <!-- <span>{{ itemCase.name }}</span> -->
            <span
              >{{ "#" + itemCase.name + " " }}{{ itemCase.zhuti || "" }}</span
            >
          </div>
          <div class="case-line-item-info-detail">
            <span>{{ itemCase.showTime }}</span>
            <span style="font-size: 24px">·</span>
            <span
              style="
                max-width: 50%;
                white-space: nowrap;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ itemCase.owneridname || $t("vue_label_SMS_nodata") }}
            </span>
            <span style="font-size: 24px">·</span>
            <span :class="itemCase.zhuangtai == '关闭' ? 'text-green' : ''">{{
              itemCase.zhuangtai
            }}</span>
          </div>
        </div>
        <div class="case-line-item-line"></div>
      </div>
    </div>
    <div class="case-line-box" v-show="showContent == 0">
      <img
        style="margin-bottom: 20px"
        src="./../assets/case_history.svg"
        alt=""
      />
      <p class="tips">{{ $t("front-console-no-contact-tip") }}...</p>
    </div>
  </div>
</template>

<script>
import * as controlPanelApi from "../api.js";

export default {
  name: "case-line",
  props: {
    // render函数中有使用
    id: {
      type: String,
    },
    caseid: {
      type: String,
    },
  },
  data() {
    return {
      showContent: -1,
      caseList: [],
      baseList: [], // 未处理的列表
      lineLoading: true,
      checkedList: [],
    };
  },
  components: {},
  methods: {
    /**
     * 父组件中同构ref调用
     */
    cancel() {
      this.render();
    },
    handledate(target) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let targetTime = new Date(target);
      let day = 24 * 60 * 60 * 1000;
      let diffTime = targetTime.getTime() + day;
      let newTarget = new Date(diffTime);
      let now = new Date();
      // 获取目标日期
      let tDate = addDateZero(targetTime.getDate());
      let tMonth = addDateZero(targetTime.getMonth() + 1);
      let tYear = targetTime.getFullYear();
      let date1 = tYear + "-" + tMonth + "-" + tDate;
      // 获取目标日期后一天
      let nDate = addDateZero(newTarget.getDate());
      let nMonth = addDateZero(newTarget.getMonth() + 1);
      let nYear = newTarget.getFullYear();
      let date2 = nYear + "-" + nMonth + "-" + nDate;
      // 获取当前日期
      let nowDate = addDateZero(now.getDate());
      let nowMonth = addDateZero(now.getMonth() + 1);
      let nowYear = now.getFullYear();
      let date3 = nowYear + "-" + nowMonth + "-" + nowDate;
      if (date1 === date3) {
        return { label: this.$i18n.t("today"), value: date1 };
      } else if (date2 === date3) {
        return { label: this.$i18n.t("lable.account.Yesterday"), value: date1 };
      } else {
        return { label: date1, value: date1 };
      }
    },
    handleArr(arr) {
      var map = {},
        dest = [];
      for (var i = 0; i < arr.length; i++) {
        var ai = arr[i];
        if (!map[ai.showDate.value]) {
          dest.push({
            id: ai.showDate.value,
            name: ai.showDate.label,
            data: [ai],
          });
          map[ai.showDate.value] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.id == ai.showDate.value) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      return dest;
    },
    handleHour(time) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let t = new Date(time);
      let hour = t.getHours();
      let min = addDateZero(t.getMinutes());
      if (hour < 12) {
        hour = addDateZero(hour);
        return hour + ":" + min + " " + "AM";
      } else {
        hour -= 12;
        hour = addDateZero(hour);
        return hour + ":" + min + " " + "PM";
      }
    },
    async changeChecked(item, index, indexCase) {
      let that = this;
      if (item.checked) {
        let res = await controlPanelApi.getPermissionById({ id: item.id });
        if (res.returnCode === "1" && res.result) {
          that.caseList[index].data[indexCase].edit = res.data.isEdit;
        }
      }
      let tempArr = [];
      that.caseList.forEach((item) => {
        tempArr = tempArr.concat(item.data);
      });
      this.checkedList = [];
      this.checkedList = tempArr.filter((item) => {
        return item.checked === true;
      });
      let per = this.checkedList.every((item) => {
        return item.edit;
      });
      that.$emit("showHebing", this.checkedList, per);
    },
    /**
     * cancel函数中调用
     */
    async render() {
      this.showContent = -1;
      this.lineLoading = true;
      let res = await controlPanelApi.getCccaseByContactid({
        contactid: this.id,
      });
      if (res.result) {
        let tempArr = res.data;
        tempArr.forEach((item) => {
          if (item.id === this.caseid) {
            item.checked = true;
            controlPanelApi.getPermissionById({ id: item.id }).then((res) => {
              if (res.returnCode === "1" && res.result) {
                item.edit = res.data.isEdit;
              }
            });
          } else {
            item.checked = false;
          }
          item.showDate = this.handledate(item.createdate);
          item.showTime = this.handleHour(item.createdate);
        });
        this.baseList = tempArr;
        this.caseList = this.handleArr(tempArr);
        if (this.baseList.length === 0) {
          this.showContent = 0;
        } else {
          this.showContent = 1;
        }
        this.$emit("showHebing", [], false);
        this.lineLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  height: 16px;
  font-size: 12px;
  color: #666666;
  line-height: 16px;
  text-align: center;
}
.case-line {
  min-height: 300px;
}
.case-line-box {
  .case-line-box-day {
    font-size: 16px;
    color: #333;
  }
  .case-line-box-date {
    margin: 10px 0;
    color: #d93127;
  }
}
.case-line-item {
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  .case-line-item-check {
    // width: 25px;
    // height: 25px;
    // background: pink;
    // margin-right: 15px;
    // z-index: 10;
    margin-top: -20px;
    ::v-deep .el-checkbox {
      margin-left: 6px;
    }
  }
  .case-line-item-info {
    margin-left: 12px;
    // margin-top: 25px;
    .case-line-item-info-title {
      width: 260px;
      font-size: 14px;
      color: #333;
      line-height: 19px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        margin-right: 10px;
      }
    }
    .case-line-item-info-detail {
      font-size: 12px;
      color: #666;
      line-height: 16px;
      display: flex;
      span {
        margin-right: 8px;
      }
      .ellipse {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #d8d8d8;
      }
      .text-green {
        color: #00c56d;
      }
    }
    &-title {
      font-size: 16px;
    }
    &-detail {
      span {
        margin-right: 5px;
      }
    }
  }
  .case-line-item-line {
    // border-left: 1px dashed #979797;
    border-left: 1px solid #dedcda;
    position: absolute;
    height: 70px;
    left: 13px;
    // top: 10px;
  }
}
::v-deep .el-checkbox__inner {
  width: 16px;
  height: 16px;
}
::v-deep .el-checkbox__inner::after {
  left: 5px;
  top: 2px;
}
</style>