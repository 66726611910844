<!--
 * @Author: your name
 * @Date: 2021-11-15 19:00:47
 * @LastEditTime: 2022-02-17 14:53:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\views\controlPanel\components\case-detail.vue
-->
<template>
  <div class="case-detail" :style="{ width: detailWidth }">
    <div
      :class="[
        'case-detail-left',
        showScrollBar ? 'case-detail-left-scroll' : 'case-detail-left-noScroll',
      ]"
      ref="caseDetailLeft"
      id="caseDetailLeft"
    >
      <div v-show="contactsActive" class="case-detail-left-contact">
        <div
          v-show="showDetailCard === 'default'"
          class="case-detail-left-contact-box"
        >
          <div class="contact-box-card">
            <div class="contact-box-card-header">
              <div class="card-header-linkman">
                <span>{{
                  contactInfo.name || $t("front-console-no-contact")
                }}</span>
                <span v-if="isWithContact">·</span>
                <div
                  v-if="isWithContact"
                  class="header-linkman-type-box"
                  @click="openCard('contact')"
                  @mouseover="handleIconContactStatus('contact1')"
                  @mouseout="handleIconContactStatus('contact2')"
                >
                  <svg
                    v-show="iconContactStatus === 'contact2'"
                    class="linkman-icon"
                    aria-hidden="true"
                  >
                    <use href="#icon-contacts_def"></use>
                  </svg>
                  <svg
                    v-show="iconContactStatus === 'contact1'"
                    class="linkman-icon"
                    aria-hidden="true"
                  >
                    <use href="#icon-contacts_cli"></use>
                  </svg>
                  <span class="card-header-linkman-type">{{
                    $t("label.contact")
                  }}</span>
                </div>
              </div>
              <!-- 没有识别出联系人 -->
              <div v-if="!isWithContact" class="without-contact">
                <div class="new-contact-btn" @click="newContact">
                  {{ $t("front-console-new-contact") }}
                </div>
                <div @click="openChooseContact" class="choose-contact-btn">
                  {{ $t("front-console-choose-contact") }}
                </div>
              </div>
              <!-- 识别出联系人 -->
              <div v-if="isWithContact" class="with-contact">
                <div
                  class="card-header-client"
                  v-show="contactInfo.accountid != null"
                >
                  <div class="">
                    <!-- {{ contactInfo.khmc }} -->
                    <el-tooltip
                      ref="tlp"
                      :content="contactInfo.khmc"
                      effect="dark"
                      :disabled="!tooltipFlag"
                    >
                      <p
                        class="card-header-client-name"
                        @mouseenter="visibilityChange($event)"
                      >
                        {{ contactInfo.khmc }}
                      </p>
                    </el-tooltip>
                  </div>
                  <span
                    v-show="contactInfo.satisfactionevaluation != null"
                    style="margin: 0 4px"
                    >·</span
                  >
                  <svg
                    v-show="
                      contactInfo.satisfactionevaluation != null &&
                      contactInfo.satisfactionevaluation > 50
                    "
                    class="card-header-client-icon1"
                    aria-hidden="true"
                  >
                    <use href="#icon-assess_facce1_good"></use>
                  </svg>
                  <svg
                    v-show="
                      contactInfo.satisfactionevaluation != null &&
                      contactInfo.satisfactionevaluation <= 50
                    "
                    class="card-header-client-icon1"
                    aria-hidden="true"
                  >
                    <use href="#icon-assess_face1_bad"></use>
                  </svg>
                  <span v-show="contactInfo.satisfactionevaluation != null"
                    >{{ contactInfo.satisfactionevaluation }}%</span
                  >
                  <span style="margin: 0 4px">·</span>
                  <div
                    class="header-client-type-box"
                    @click="openCard('client')"
                    @mouseover="handleIconClientStatus('client1')"
                    @mouseout="handleIconClientStatus('client2')"
                  >
                    <svg
                      v-show="iconClientStatus === 'client2'"
                      class="card-header-client-icon2"
                      aria-hidden="true"
                    >
                      <use href="#icon-client_def"></use>
                    </svg>
                    <svg
                      v-show="iconClientStatus === 'client1'"
                      class="card-header-client-icon2"
                      aria-hidden="true"
                    >
                      <use href="#icon-client_cli"></use>
                    </svg>
                    <span>{{ $t("label.account") }}</span>
                  </div>
                </div>
                <div class="card-header-email">
                  {{ contactInfo.email }}
                </div>
                <div
                  class="card-header-phone"
                  v-show="
                    contactInfo.shouji != null || contactInfo.dianhua != null
                  "
                >
                  <span>
                    {{ contactInfo.shouji || contactInfo.dianhua }}
                  </span>
                  <svg
                    class="card-header-phone-icon"
                    aria-hidden="true"
                    @click="
                      callPhone(contactInfo.shouji || contactInfo.dianhua)
                    "
                  >
                    <use href="#icon-Call"></use>
                  </svg>
                </div>
                <!-- <div class="card-header-website">https:www.cloudcc.com</div> -->
              </div>
            </div>
            <!-- 固定字段 -->
            <div class="contact-box-card-center">
              <div class="card-center-item">
                <div class="card-center-label">
                  {{ $t("label.assignment.setup.detail.assigner") }}
                </div>
                <div class="card-center-value juzhong">
                  <svg class="svg" aria-hidden="true">
                    <use href="#icon-console_case_contactsLoge"></use>
                  </svg>
                  {{ caseInfo.owneridname || "" }}
                  <span
                    v-if="isEdit"
                    class="change-owner"
                    @click="openChangeOwner"
                    >[{{ $t("label.change") }}]</span
                  >
                </div>
              </div>
              <div class="card-center-item">
                <div class="card-center-label">{{ $t("label.state") }}</div>
                <div class="card-center-value">
                  <el-select
                    @change="changeStatus"
                    v-model="caseValue"
                    :disabled="!isEdit"
                    :placeholder="$t('label.select.please')"
                  >
                    <el-option
                      v-for="item in caseOptions"
                      :key="item.codekey"
                      :label="item.codevalue"
                      :value="item.codekey"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="card-center-item">
                <div class="card-center-label">{{ showDateLabel }}</div>
                <div class="card-center-value">
                  <span
                    v-if="caseValue === '关闭'"
                    class="card-center-value-date"
                  >
                    {{ caseInfo.closetime || $t("vue_label_SMS_nodata") }}
                  </span>
                  <span
                    v-else-if="caseValue === '等待' || caseValue === '搁置'"
                    class="card-center-value-date"
                  >
                    {{ caseInfo.holdtime || $t("vue_label_SMS_nodata") }}
                  </span>
                  <template v-else>
                    <el-date-picker
                      v-show="isEditDueDate"
                      :clearable="false"
                      v-model="caseDueDate"
                      type="datetime"
                      @change="handleDuedate"
                      :placeholder="$t('vue_label_commonobject_selectdatatime')"
                    >
                    </el-date-picker>
                    <span
                      v-if="isEditDueDate"
                      class="change-owner"
                      @click="changeDueDate(0)"
                      >[{{ $t("label.cancel") }}]</span
                    >
                    <span
                      class="card-center-value-date"
                      v-show="!isEditDueDate"
                    >
                      {{
                        caseInfo.duedate ||
                        $t(
                          "vue_label_commonobjects_detail_activity_noexpirationdate"
                        )
                      }}
                      <span
                        v-if="isEdit"
                        class="change-owner"
                        @click="changeDueDate(1)"
                        >[{{ $t("label.change") }}]</span
                      >
                    </span>
                    <span
                      class="card-center-value-overdue"
                      v-show="showOverdue && !isEditDueDate"
                      >{{ caseInfo.duedateStr }}
                    </span>
                  </template>
                </div>
              </div>
              <div class="card-center-item">
                <div class="card-center-label">
                  {{ $t("label.RecordType") }}
                </div>
                <div class="card-center-value">
                  {{ caseInfo.recordtypevalue || "" }}
                </div>
              </div>
            </div>
            <!-- 详情布局 -->
            <div class="contact-box-card-footer">
              <project360
                ref="project360"
                id="listChild"
                showPart="detail"
                :dataId="id"
                :objectApi="objectApi"
                :prefix="prefix"
                :isEditDetail="isEdit"
                :isQueryDetail="isQuery"
                :locked="isLocked"
                :canEditOwner="isCanEditOwner"
                detailType="controlPanel"
                @refreshAll="showDetail"
              ></project360>
            </div>
          </div>
        </div>
        <detailCard
          ref="detailCard"
          v-show="showDetailCard === 'detail'"
          @closeCard="closeCard"
        >
        </detailCard>
        <chooseContact
          ref="chooseContact"
          @closeCard="closeCard"
          :id="id"
          v-show="showDetailCard === 'contact'"
        >
        </chooseContact>
        <div class="detail-left-bottom" v-show="false">
          <el-button size="mini">{{ $t("label.cancel") }}</el-button>
          <el-button type="primary" size="mini">{{
            $t("label.save")
          }}</el-button>
        </div>
      </div>
      <div v-show="knowLege" class="case-detail-left-knowlege">
        <caseDetailKonwlege ref="DetailKonwlege" :id="id"></caseDetailKonwlege>
      </div>
      <div v-show="caseHistory" class="case-detail-left-history">
        <div class="case-detail-left-history-box">
          <caseLine
            :id="contactInfo.id"
            :caseid="id"
            @showHebing="showHebing"
            ref="caseHistory"
          ></caseLine>
        </div>
        <div class="history-btn-box" v-show="Hebing > 1">
          <el-button size="mini" @click="cancelCaseHe">
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            type="primary"
            :disabled="!HebingBtn"
            size="mini"
            @click="caseHebing"
          >
            {{ $t("message.duplication.label.mergebutton") }}
          </el-button>
        </div>
      </div>
    </div>
    <div
      :class="[
        'edit-line',
        {
          'edit-line-active': foldActive == false,
        },
      ]"
    >
      <div
        @click="changeImg('contacts')"
        :class="[
          'active-item-contact',
          'common-item',
          {
            'active-item-contact-active': contactsActive == false,
          },
        ]"
      >
        <svg
          class="aside-icon"
          aria-hidden="true"
          v-show="contactsActive == true"
        >
          <use href="#icon-case_cli"></use>
        </svg>
        <svg
          class="aside-icon"
          aria-hidden="true"
          v-show="contactsActive == false"
        >
          <use href="#icon-case_def"></use>
        </svg>
      </div>
      <div
        @click="changeImg('knowlege')"
        :class="[
          'active-item-knowlege',
          'common-item',
          {
            'active-item-knowlege-active': knowLege == false,
          },
        ]"
      >
        <svg class="aside-icon" aria-hidden="true" v-show="knowLege == true">
          <use href="#icon-knowledge_cli"></use>
        </svg>
        <svg class="aside-icon" aria-hidden="true" v-show="knowLege == false">
          <use href="#icon-knowledge_def"></use>
        </svg>
      </div>
      <div
        @click="changeImg('history')"
        :class="[
          'active-item-history',
          'common-item',
          {
            'active-item-history-active': caseHistory == false,
          },
        ]"
      >
        <svg class="aside-icon" aria-hidden="true" v-show="caseHistory == true">
          <use href="#icon-history_cli"></use>
        </svg>
        <svg
          class="aside-icon"
          aria-hidden="true"
          v-show="caseHistory == false"
        >
          <use href="#icon-history_def"></use>
        </svg>
      </div>
    </div>
    <div
      v-show="foldActive"
      class="fold-content common-item"
      @click="handleFold('close')"
    >
      <span class="fold-open">
        <svg class="fold-content-icon" aria-hidden="true">
          <use href="#icon-console_putAway"></use>
        </svg>
      </span>
    </div>
    <div class="case-detail-right" id="case-detail-right">
      <!-- 项目详情 -->
      <!-- 中文key -->
      <div class="status-bar" v-show="caseValue === '关闭'">
        <div class="status-bar-text">
          {{ $t("mobel_web_closed") }}
        </div>
      </div>
      <div
        class="status-bar status-bar-pending"
        v-show="caseValue === '等待' || caseValue === '搁置'"
      >
        <div class="status-bar-text">
          {{ $t("front-console-status-hoided") }}
        </div>
      </div>
      <project-detail
        ref="caseDetail"
        detailType="controlPanel"
        style="padding: 10px 10px 0px 10px"
        :data-id="id"
      />
    </div>
    <!-- 转移所有人-->
    <transfer-owner-dialog
      :id="id"
      :objId="objId"
      :show-select-num="true"
      :select-num="selectNum"
      :transfer-deault="transferDeault"
      :owner-type-list="ownerTypeList"
      :owner-raletion-list="ownerRaletionList"
      ref="transferClientOwner"
      :transfer-client-owner-dialog="transferClientOwnerDialog"
      @closeTransferOwnerDialog="closeTransferOwnerDialog"
      @saveTransferOwner="saveTransferOwner"
    ></transfer-owner-dialog>
    <!-- 新建 -->
    <create-edit-obj
      ref="createEditObj"
      prefix="003"
      objectApi="Contact"
      :object-name="$t('label.contact')"
      type="active"
      :isConsoleNewContact="true"
      @save="save"
    >
    </create-edit-obj>
  </div>
</template>

<script>
import ProjectDetail from "../../commonObjects/project-detail/index.vue";
import caseLine from "./case-line.vue";
import project360 from "@/views/commonObjects/project-detail/project360";
import caseDetailKonwlege from "./case-detail-konwlege.vue";
import detailCard from "@/views/controlPanel/components/detail-card";
import chooseContact from "@/views/controlPanel/components/choose-contact";
import * as CommonObjApi from "../api";
import * as controlPanelApi from "../api.js";
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import TransferOwnerDialog from "@/components/TransferOwnerDialog/index.vue";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import * as knowledgearticles from "../api.js"; // 请求api

export default {
  mixins: [TransferOwnerMixin],
  props: {
    caseid: {
      type: String,
      default: "",
    },
    viewId: {
      type: String,
      default: "",
    },
  },
  components: {
    TransferOwnerDialog,
    ProjectDetail,
    caseLine,
    project360,
    caseDetailKonwlege,
    detailCard,
    chooseContact,
    createEditObj,
  },
  data() {
    return {
      id: this.$route.query.caseId || this.caseid,
      objId: "201100000005024CxOSz",
      objectApi: "cloudcccase",
      prefix: "008",
      isQuery: false, // 记录可查权限
      isCanEditOwner: false, //判断是否有更改所以人权限
      isLocked: false, //是否锁定
      isEdit: false, // 编辑权限
      selectNum: 1,
      transferClientOwnerDialog: false,
      caseInfo: {}, // 个案基本4项信息
      contactInfo: {}, // 联系人信息
      iconClientStatus: "client2",
      iconContactStatus: "contact2",
      isWithContact: false, // 是否识别出联系人
      detailWidth: "calc(100% - 272px)",
      caseOptions: [], // 状态选项列表
      caseValue: "", // 状态
      showDateLabel: this.$i18n.t("label.expiredate"),
      showOverdue: false, // 是否超时
      showDetailCard: "default",
      knowLege: false,
      contactsActive: true,
      caseHistory: false,
      foldActive: true,
      Hebing: 0, // 合并数量
      HebingArr: [], // 合并数量
      HebingBtn: false, // 合并按钮权限
      isEditDueDate: false,
      caseDueDate: "", // 个案到期日期
      tooltipFlag: false,
      showScrollBar: false, // 展示滚动条
    };
  },
  watch: {
    caseValue(newVal) {
      if (newVal === "等待" || newVal === "搁置") {
        this.showOverdue = false;
        this.showDateLabel = this.$i18n.t("front-console-holdTime");
      } else if (newVal === "关闭") {
        this.showOverdue = false;
        this.showDateLabel = this.$i18n.t("front-console-closeTime");
      } else {
        this.showDateLabel = this.$i18n.t("label.expiredate");
        // 处理逾期
        // this.showOverdue
      }
      this.$bus.$emit("serviceCaseStatusIn", newVal);
    },
  },
  methods: {
    // 处理文本过长
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度
      const content_weight = this.$refs.tlp.$el.parentNode.clientWidth; // 文本容器宽度(父节点)
      if (ev_weight > content_weight) {
        // 文本宽度 > 实际内容宽度  =》内容溢出
        this.tooltipFlag = true;
      } else {
        // 否则为不溢出
        this.tooltipFlag = false;
      }
    },
    // 新增/修改保存
    save(id) {
      let conf = {
        caseid: this.id,
        contactid: id,
      };
      controlPanelApi
        .associateContact(conf)
        .then((res) => {
          if (res.result) {
            this.showDetail();
          }
        })
        .catch(() => {});
    },
    // 新建联系人
    newContact() {
      this.$refs.createEditObj.add();
    },
    // 打开更改所有人
    openChangeOwner() {
      if (this.isEdit) {
        this.initTransferOwner(this.objId, this.id);
        this.transferClientOwnerDialog = true;
      }
    },
    // 更改所有人
    // saveTransferOwnerSuccess() {
    //   this.showDetail();
    //   this.closeTransferOwnerDialog();
    // },
    closeTransferOwnerDialog() {
      this.transferClientOwnerDialog = false;
    },
    showHebing(arr, edit) {
      this.HebingArr = arr;
      this.Hebing = arr.length;
      this.HebingBtn = edit;
    },
    // 打开选择联系人
    openChooseContact() {
      this.showDetailCard = "contact";
      this.$refs.chooseContact.render();
    },
    // 打开联系人、客户卡片
    openCard(obj) {
      this.$refs.detailCard.object = obj;
      this.$refs.detailCard.render(this.contactInfo);
      this.showDetailCard = "detail";
    },
    // 关闭联系人、客户卡片
    closeCard(flag) {
      if (flag == "fresh") {
        this.showDetail();
      }
      this.showDetailCard = "default";
    },
    // 更改个案状态
    changeStatus(val) {
      let status;
      // this.caseValue = val;
      switch (val) {
        case "On Hold":
          status = "wait";
          break;
        case "等待":
          status = "wait";
          break;
        case "Escalated":
          status = "upgrade";
          break;
        case "升级":
          status = "upgrade";
          break;
        case "Return Visited":
          status = "returnvisit";
          break;
        case "回访":
          status = "returnvisit";
          break;
        case "Closed":
          status = "close";
          break;
        case "关闭":
          status = "close";
          break;
        case "等待工程师":
          status = "engineer";
          break;
        case "New":
          status = "new";
          break;
        case "新建":
          status = "new";
          break;
      }
      let conf = {
        caseid: this.id,
        status: status,
      };
      controlPanelApi.changeCaseStatus(conf).then((res) => {
        if (res.result) {
          this.$nextTick(() => {
            this.$refs.caseDetail.getBrief();
            this.showDetail();
          });
        }
      });
    },
    // 联系人信息图标状态
    handleIconContactStatus(i) {
      this.iconContactStatus = i;
    },
    // 客户信息图标状态
    handleIconClientStatus(i) {
      this.iconClientStatus = i;
    },
    // 获取逾期时间差

    countTime(duedate) {
      //获取当前时间
      let date = new Date();
      let now = date.getTime();
      //设置截止时间
      let endDate = new Date(duedate);
      let end = endDate.getTime();
      //时间差
      let leftTime = end - now;
      //定义变量 day,hour,minute
      if (leftTime >= 0) {
        this.showOverdue = false;
      } else {
        let leftTime2 = leftTime * -1;
        let day = Math.floor(leftTime2 / 1000 / 60 / 60 / 24);
        let hour = Math.floor((leftTime2 / 1000 / 60 / 60) % 24);
        let minute = Math.floor((leftTime2 / 1000 / 60) % 60);
        // let second = Math.floor((leftTime2 / 1000) % 60);
        this.showOverdue = true;
        if (day > 0) {
          return (
            this.$i18n.t("label.projectManagement.overdue") +
            " " +
            day +
            " " +
            this.$i18n.t("label.chatter.day") +
            " " +
            hour +
            " " +
            this.$i18n.t("label.ems.hour") +
            " " +
            minute +
            " " +
            this.$i18n.t("label.ems.minute")
          );
        } else if (hour > 0) {
          return (
            this.$i18n.t("label.projectManagement.overdue") +
            " " +
            hour +
            " " +
            this.$i18n.t("label.ems.hour") +
            " " +
            minute +
            " " +
            this.$i18n.t("label.ems.minute")
          );
        }
      }
    },
    handleTime(target) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      function handleHour(time) {
        let t = new Date(time);
        let hour = t.getHours();
        let min = addDateZero(t.getMinutes());
        let sec = addDateZero(t.getSeconds());
        if (hour < 12) {
          hour = addDateZero(hour);
          return hour + ":" + min + ":" + sec + " " + "AM";
        } else {
          hour -= 12;
          hour = addDateZero(hour);
          return hour + ":" + min + ":" + sec + " " + "PM";
        }
      }
      let targetTime = new Date(target);
      let day = 24 * 60 * 60 * 1000;
      let diffTime = targetTime - day;
      let newTarget = new Date(diffTime);
      let now = new Date();
      // 获取目标日期
      let tDate = addDateZero(targetTime.getDate());
      let tMonth = addDateZero(targetTime.getMonth() + 1);
      let tYear = targetTime.getFullYear();
      let date1 = tYear + "-" + tMonth + "-" + tDate;
      // 获取目标日期前一天
      let nDate = addDateZero(newTarget.getDate());
      let nMonth = addDateZero(newTarget.getMonth() + 1);
      let nYear = newTarget.getFullYear();
      let date2 = nYear + "-" + nMonth + "-" + nDate;
      // 获取当前日期
      let nowDate = addDateZero(now.getDate());
      let nowMonth = addDateZero(now.getMonth() + 1);
      let nowYear = now.getFullYear();
      let date3 = nowYear + "-" + nowMonth + "-" + nowDate;

      if (date1 === date3) {
        return this.$i18n.t("today") + " " + handleHour(target);
      } else if (date2 === date3) {
        return this.$i18n.t("tomorrow") + " " + handleHour(target);
      } else {
        return date1 + " " + handleHour(target);
      }
    },
    // 获取个案固定四项信息
    async getBaseInfo() {
      let conf = {
        caseid: this.id,
      };
      let that = this;
      let res = await controlPanelApi.getDateByCaseid(conf);
      if (res.result) {
        let duedate = res.data[0].duedate;
        that.caseInfo = res.data[0];
        that.caseDueDate = res.data[0].duedate;
        that.caseValue = that.caseInfo.zhuangtai;
        if (that.caseInfo.duedate) {
          that.caseInfo.duedate = that.handleTime(that.caseInfo.duedate);
          that.caseInfo.duedateStr = that.countTime(duedate);
        }
        if (that.caseInfo.closetime) {
          that.caseInfo.closetime = that.handleTime(that.caseInfo.closetime);
        }
        if (that.caseInfo.holdtime) {
          that.caseInfo.holdtime = that.handleTime(that.caseInfo.holdtime);
        }
      }
    },
    handleDuedate() {
      let date = new Date(this.caseDueDate);
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let tSec = addDateZero(date.getSeconds());
      let tMin = addDateZero(date.getMinutes());
      let tHour = addDateZero(date.getHours());
      let tDate = addDateZero(date.getDate());
      let tMonth = addDateZero(date.getMonth() + 1);
      let tYear = date.getFullYear();
      let date1 =
        tYear +
        "-" +
        tMonth +
        "-" +
        tDate +
        " " +
        tHour +
        ":" +
        tMin +
        ":" +
        tSec;
      let arr = [
        {
          id: this.id,
          duedate: date1,
        },
      ];
      let con = {
        fields: "duedate",
        jsonstr: JSON.stringify(arr),
        objectApi: "cloudcccase",
      };
      CommonObjApi.updateViewListAjax(con)
        .then((res) => {
          if (res.result) {
            this.isEditDueDate = false;
            this.getBaseInfo();
          }
        })
        .catch(() => {});
    },
    changeDueDate(flag) {
      if (flag > 0) {
        this.isEditDueDate = true;
      } else {
        this.isEditDueDate = false;
      }
    },
    // 获取个案固定四项信息--状态列表
    getStatusList() {
      CommonObjApi.getSelectValue({ fieldId: "ffe201100005223jgMfn" }).then(
        (response) => {
          if (response.result) {
            this.caseOptions = response.data;
          }
        }
      );
    },
    // 获取联系人信息
    async getContact() {
      let conf = {
        caseid: this.id,
      };
      let res = await controlPanelApi.getContactInfo(conf);
      if (res.result) {
        if (res.data.length === 1) {
          this.isWithContact = true;
          this.contactInfo = res.data[0];
        } else if (res.data.length === 0) {
          this.isWithContact = false;
          this.contactInfo = {};
        }
      } else {
        this.isWithContact = false;
        this.contactInfo = {};
      }
    },
    /**
     * 父组件中使用了
     */
    showDetail(id) {
      if (id) {
        this.id = id;
        this.changeImg("contacts");
      }
      this.getContact();
      this.getBaseInfo();
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.returnCode === "1" && res.result) {
          this.objectApi = res.data.objectApi;
          this.prefix = res.data.prefix;
          this.isEdit = res.data.isEdit;
          this.objId = res.data.objId;
          this.isLocked = res.data.isLocked;
          this.isCanEditOwner = res.data.canEditOwner;
          this.isQuery = res.data.isQuery;
          this.$nextTick(() => {
            //切换个案卡片时关掉邮件页
            this.$Bus.$emit("Close-mail-page");
            //切换个案卡片时关掉聊天详情
            this.$Bus.$emit("is-show-detail");
            this.$refs.project360.init(this.id);
          });
        }
      });
    },
    // 取消合并
    cancelCaseHe() {
      this.$refs.caseHistory.cancel();
    },
    // 合并
    caseHebing() {
      if (this.Hebing > 10) {
        this.$message.warning(this.$i18n.t("front-console-select-cases"));
        return false;
      }
      let idStr = "";
      this.HebingArr.forEach((item) => {
        idStr += item.id + ",";
      });
      this.$router.push({
        path:
          "/commonObjects/combination?ids=" +
          idStr +
          "&prefix=008&labelName=" +
          this.$i18n.t("label.case") +
          "&isConsole=true&viewId=" +
          this.viewId,
      });
    },
    changeImg(type) {
      if (type == "contacts") {
        this.showDetailCard = "default";
        this.contactsActive = true;
        this.knowLege = false;
        this.caseHistory = false;
      } else if (type == "knowlege") {
        // 判断是否有知识库用户
        knowledgearticles.getKnowledgePermissionById().then((res) => {
          if (res.data.isKnowledgeUser) {
            this.knowLege = true;
            this.contactsActive = false;
            this.caseHistory = false;
            this.$refs.DetailKonwlege.recommendedArticleQuery();
          } else {
            this.$message(this.$i18n.t("c61"));
          }
        });
      } else if (type == "history") {
        // if (this.contactInfo.id) {
        this.knowLege = false;
        this.contactsActive = false;
        this.caseHistory = true;
        this.$refs.caseHistory.render();
        // } else {
        //   this.$message.warning("请先关联联系人");
        // }
      }
    },
    /**
     * 父组件中调用了
     */
    handleFold(flag) {
      if (flag === "open") {
        this.foldActive = true;
        this.changeDetailWidth();
      } else if (flag === "close") {
        this.foldActive = false;
        this.$emit("fold");
        this.changeDetailWidth();
      }
    },
    changeDetailWidth() {
      if (this.foldActive) {
        this.detailWidth = "calc(100% - 272px)";
      } else {
        this.detailWidth = "calc(100% - 60px)";
      }
      this.$nextTick(() => {
        this.updateBar();
      });
    },
    updateBar() {
      let width;
      let detailDom = document.getElementById("case-detail-right");
      if (detailDom) {
        width = `calc(100% - ${detailDom.getBoundingClientRect().left}px)`;
      }
      this.$bus.$emit("changeCloseBae", width);
    },
    callPhone(phone) {
      this.$bus.$emit("newCLick", phone);
    },
    // 监听中间滚动
    scrollCenter() {
      let roll = 0; // 滚动的值
      let stop = 0; // 对比时间的值
      let timer = null;
      let that = this;
      this.$refs.caseDetailLeft.addEventListener(
        "scroll",
        (event) => {
          var e = event || event.target;
          // 每次滑动前都清除一遍我们定义的定时器
          clearTimeout(timer);
          this.showScrollBar = true;
          roll = e.srcElement.scrollTop;
          // 每次滚动的时候，都让box回到原来的宽度
          timer = setTimeout(function () {
            stop = document.getElementById("caseDetailLeft").scrollTop;
            if (stop == roll) {
              that.showScrollBar = false;
            }
          }, 1500);
        },
        true
      );
    },
    serviceCaseStatusOutFn(val) {
      this.changeStatus(val);
    }
  },
  mounted() {
    this.$bus.$on("serviceCaseStatusOut", this.serviceCaseStatusOutFn);
    this.getStatusList();
    this.scrollCenter();
    this.updateBar();
    this.$nextTick(() => {
      this.showDetail();
    });
  },
  beforeDestroy() {
    this.$bus.$off("serviceCaseStatusOut", this.serviceCaseStatusOutFn);
  }
};
</script>

<style lang="scss" scoped>
.case-detail {
  height: 100%;
  width: calc(100% - 272px);
  display: flex;
  position: relative;
  box-shadow: -4px 0px 8px 0px rgba(0, 0, 0, 0.3);
  .case-detail-left-noScroll::-webkit-scrollbar {
    width: 0px;
    height: 10px;
  }
  .case-detail-left-noScroll {
    padding-right: 10px;
  }
  .case-detail-left-scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .case-detail-left {
    width: 388px;
    background: #ffffff;
    box-shadow: -4px 0px 8px 0px rgba(0, 0, 0, 0.3);
    overflow: auto;
    // ::-webkit-scrollbar {
    //   display: none;
    // }

    .case-detail-left-contact {
      &-box {
        padding: 20px 30px 0 40px;
        .contact-box-card {
          .contact-box-card-header {
            border-bottom: 1px dashed #9e9e9e;
            margin-left: 20px;
            .card-header-linkman {
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              span:first-child {
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                line-height: 21px;
                margin-right: 4px;
              }
              span:nth-child(2) {
                margin-right: 4px;
                color: #999999;
                line-height: 16px;
              }
              .header-linkman-type-box {
                cursor: pointer;
                display: flex;
                align-items: center;
                .card-header-linkman-type {
                  font-size: 12px;
                  color: #999999;
                  line-height: 16px;
                }
                .linkman-icon {
                  margin-right: 8px;
                  height: 20px;
                  width: 20px;
                  cursor: pointer;
                }
              }
              .header-linkman-type-box:hover {
                .card-header-linkman-type {
                  color: #006dcc;
                }
              }
            }
            .card-header-client {
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              &-name {
                font-size: 14px;
                color: #333333;
                line-height: 19px;
                max-width: 108px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &-icon1 {
                width: 16px;
                height: 16px;
                margin-right: 4px;
              }
              span {
                font-size: 14px;
                color: #999999;
                line-height: 19px;
              }
              .header-client-type-box {
                cursor: pointer;
                display: flex;
                align-items: center;
                .card-header-client-icon2 {
                  width: 20px;
                  height: 20px;
                  margin: 0 4px;
                }
                span {
                  cursor: pointer;
                  font-size: 12px;
                  color: #999999;
                  // color: #006dcc;
                  line-height: 16px;
                }
              }
              .header-client-type-box:hover {
                span {
                  color: #006dcc;
                }
              }
            }
            .card-header-email {
              margin-bottom: 8px;
              font-size: 14px;
              color: #333333;
              line-height: 19px;
            }
            .card-header-phone {
              margin-bottom: 16px;
              font-size: 14px;
              color: #333333;
              line-height: 19px;
              display: flex;
              align-items: center;
              span {
                margin-right: 8px;
              }
              &-icon {
                width: 14px;
                height: 14px;
                cursor: pointer;
              }
            }
            .card-header-website {
              margin-bottom: 14px;
              font-size: 14px;
              color: #333333;
              line-height: 19px;
            }
            .without-contact {
              display: flex;
              margin-bottom: 8px;
              padding-top: 12px;
              .new-contact-btn {
                padding: 6px 16px;
                cursor: pointer;
                height: 30px;
                background: #ffffff;
                border-radius: 3px;
                border: 1px solid #006dcc;
                font-size: 12px;
                color: #006dcc;
                line-height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
              }
              .choose-contact-btn {
                padding: 6px 16px;
                cursor: pointer;
                height: 30px;
                background: #006dcc;
                border-radius: 3px;
                font-size: 12px;
                color: #ffffff;
                line-height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .contact-box-card-center {
            padding-top: 16px;
            margin-left: 20px;
            // border-bottom: 1px dashed #9e9e9e;
            .card-center-item {
              .card-center-label {
                font-size: 12px;
                color: #666666;
                line-height: 16px;
                margin-bottom: 8px;
              }
              .card-center-value {
                font-size: 14px;
                font-weight: bold;
                color: #333333;
                line-height: 19px;
                margin-bottom: 16px;
                &-date {
                  margin-bottom: 4px;
                }
                &-overdue {
                  display: block;
                  font-size: 14px;
                  color: #d93127;
                  line-height: 19px;
                }
                ::v-deep .el-select {
                  width: 100%;
                  input {
                    height: 35px;
                  }
                  ::v-deep .el-input__icon {
                    line-height: 35px;
                  }
                }
              }
            }
          }
          .contact-box-card-footer {
          }
        }
      }
      .detail-left-bottom {
        width: 388px;
        height: 44px;
        background: #ffffff;
        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.32);
        // width: 300px;
        position: absolute;
        bottom: 0;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
      }
    }
    .case-detail-left-history {
      &-box {
        padding: 20px 40px 0 40px;
      }
      .history-btn-box {
        width: 388px;
        height: 44px;
        background: #ffffff;
        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.32);
        // width: 300px;
        position: absolute;
        bottom: 0;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
      }
    }
  }
  .case-detail-right {
    position: relative;
    width: calc(100% - 388px);
    .status-bar {
      position: absolute;
      top: -50px;
      right: 40px;
      transform: rotate(-45deg);
      width: 40px;
      height: 220px;
      padding: 10px;
      background: #00c56d;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      &-text {
        transform: rotate(90deg);
        white-space: nowrap;
        width: 78px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 21px;
      }
    }
    .status-bar-pending {
      background: #f68900;
    }
  }
  .fold-content {
    position: absolute;
    left: -12px;
    top: 55%;
    width: 12px;
    height: 84px;
    background: #999999;
    border-radius: 4px 0px 0px 4px;
    .fold-content-icon {
      width: 10px;
      height: 10px;
    }
  }
  .edit-line {
    position: absolute;
    top: 160px;
    left: -36px;
    border-right: none;
    font-size: 24px;
    box-shadow: -4px 0px 8px 0px rgba(0, 0, 0, 0.3);
    border-radius: 8px 0px 0px 8px;
    .active-item-contact {
      width: 36px;
      height: 40px;
      background: #ffffff;
      border-radius: 8px 0px 0px 0px;
    }
    .active-item-contact-active {
      width: 36px;
      height: 40px;
      background: linear-gradient(135deg, #666666 0%, #2e2e2e 100%);
      border-radius: 8px 0px 0px 0px;
    }
    .active-item-knowlege {
      width: 36px;
      height: 40px;
      background: #ffffff;
    }
    .active-item-knowlege-active {
      width: 36px;
      height: 40px;
      background: linear-gradient(45deg, #666666 0%, #2e2e2e 100%);
    }
    .active-item-history {
      width: 36px;
      height: 40px;
      background: #ffffff;
      border-radius: 0px 0px 0px 8px;
    }
    .active-item-history-active {
      width: 36px;
      height: 40px;
      background: linear-gradient(135deg, #666666 0%, #2e2e2e 100%);
      border-radius: 0px 0px 0px 8px;
    }
    .active-item-active {
      background: #000;
    }
  }
  // .edit-line-active {
  //   left: 0;
  // }
}
.aside-icon {
  width: 16px;
  height: 16px;
}
.common-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.case-list-sort {
  // height: 36px;
  padding: 0 20px;
  background: #fafaf9;
  display: flex;
  align-items: center;
  // justify-content: space-around;
  .case-list-sort-item {
    width: 50%;
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    .case-list-sort-item-unchecked {
      font-size: 14px;
      color: #666666;
      line-height: 19px;
      padding: 10px 0;
    }
    .case-list-sort-item-checked {
      color: #000;
      border-bottom: 1px solid #000;
    }
  }
}
.juzhong {
  display: flex;
  align-items: center;
  .svg {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
}
.change-owner {
  cursor: pointer;
  color: #006dcc;
  margin-left: 5px;
}
</style>