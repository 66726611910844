<template>
  <div class="control">
    <div class="control-container">
      <div class="control-container-box">
        <case-list
          v-show="showList"
          @postView="postView"
          @checkCase="checkCase"
          @getcaseData="getcaseData"
          :key="timerA"
        >
        </case-list>
        <div v-show="!showList" class="space">
          <img
            @click="openAside"
            class="space-icon"
            src="./assets/open-aside.svg"
            alt=""
          />
        </div>
        <case-detail
          ref="caseDetail"
          :csseid="id"
          :viewId="viewId"
          @fold="fold"
          @unfold="unfold"
          :caseData="caseData"
          :key="timerB"
        ></case-detail>
      </div>
      <div class="control-container-footer">
        <!-- 暂时隐藏 -->
        <!-- <call-bar></call-bar> -->
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 服务控制台
 */
import CaseList from "./components/case-list";
import CaseDetail from "./components/case-detail";
export default {
  name: "controlPanel",
  components: {
    CaseList,
    CaseDetail,
  },
  data() {
    return {
      caseData: null,
      showList: true,
      id: "",
      viewId: this.$route.query.viewId,
      timerA: "",
      timerB: "",
    };
  },

  watch: {
    $route() {
      this.reloadControl(true);
    },
  },
  methods: {
    openAside() {
      this.showList = true;
      this.$refs.caseDetail.handleFold("open");
    },
    fold() {
      this.showList = false;
    },
    unfold() {
      this.showList = true;
    },
    getcaseData(item) {
      this.caseData = item;
    },
    checkCase(obj) {
      this.id = obj.id;
      this.orderNum = obj.name;
      this.$refs.caseDetail.showDetail(this.id);
    },
    postView(str) {
      this.viewId = str;
      // this.$refs.caseDetail.showDetail(this.id)
    },

    // 刷新控制台
    reloadControl(Bool) {
      if (Bool) {
        this.timerA = new Date().getTime();
      }
      this.timerB = new Date().getTime();
    },
    openNewCaseFn(caseid, casename) {
      const newPage = this.$router.resolve({
        path: "/controlPanel/control",
        query: {
          caseId: caseid,
          caseOrder: casename,
          viewId: this.viewId,
        },
      });
      window.open(newPage.href, "_blank");
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    // 打开新的个案
    this.$bus.$on("openNewCase", this.openNewCaseFn);
    this.$bus.$on("reloadControl", this.reloadControl);
  },
  beforeDestroy() {
    this.$bus.$off("openNewCase", this.openNewCaseFn);
    this.$bus.$off("reloadControl", this.reloadControl);
  }
};
</script>

<style lang="scss" scoped>
.control {
  width: 100%;
  height: 100%;
  // padding: 10px;
  .control-container {
    background: #ffffff;
    border-radius: 3px;
    // padding-bottom: 20px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    .control-container-box {
      width: 100%;
      // height: calc(100% - 20px);
      height: 100%;
      display: flex;
      border-bottom: 1px solid #dedcda;
    }
    .control-container-footer {
      // height: 20px;
      width: 100%;
    }
  }
}
.space {
  width: 60px;
  height: 100%;
  background-color: #fff;
  position: relative;
  .space-icon {
    position: absolute;
    height: 200px;
    width: 54px;
    cursor: pointer;
    left: -16px;
    top: 35%;
  }
}
</style>