<template>
  <div class="container">
    <div class="header">
      <i class="el-icon-back header-icon" @click="goBackCase"></i>
      <span class="header-title">
        {{ $t("front-console-choose-contact") }}
      </span>
      <span> </span>
    </div>
    <div class="blank-space"></div>
    <div class="content-box">
      <div class="center">
        <div class="header-icon">
          <svg class="header-icon" aria-hidden="true">
            <use href="#icon-console_case_contactsLoge"></use>
          </svg>
        </div>
        <div class="header-name">{{ $t("front-console-no-contact") }}</div>
      </div>
      <div class="footer">
        <div v-show="contactNum === 0">
          <div class="footer-tab">
            {{ $t("front-console-no-reco-contact") }}
          </div>
          <div class="no-contact">
            <img src="./../assets/no-contact.svg" alt="" />
            <div class="new-contact">
              <div class="new-contact-btn" @click="newContact">
                {{ $t("front-console-new-contact") }}
              </div>
            </div>
          </div>
          <!-- 新建 -->
          <create-edit-obj
            ref="createEditObj"
            prefix="003"
            objectApi="Contact"
            :object-name="$t('label.contact')"
            type="active"
            :isConsoleNewContact="true"
            @save="save"
          >
          </create-edit-obj>
        </div>
        <div v-show="contactList.length > 0">
          <div class="footer-tab">
            {{ $t("front-console-see-contacts", { num: contactNum }) }}
          </div>
          <div class="contact-list">
            <div
              v-for="(item, index) in contactList"
              :key="index"
              class="contact-list-item"
            >
              <svg class="header-icon" aria-hidden="true" @click="newTabDetail(item)">
                <use href="#icon-share"></use>
              </svg>
              <div class="contact-item">
                <div class="contact-item-label">
                  {{$t("label.mr.title.contactName")}}
                </div>
                <div class="contact-item-value">{{ item.name }}</div>
              </div>
              <div class="contact-item">
                <div class="contact-item-label">
                  {{ $t("label.customername") }}
                </div>
                <div class="contact-item-value">{{ item.khmc }}</div>
              </div>
              <div class="contact-item">
                <div class="contact-item-label">{{ $t("label.mobile") }}</div>
                <div class="contact-item-value">
                  {{ item.shouji || item.dianhua }}
                </div>
              </div>
              <div class="contact-item">
                <div class="contact-item-label">{{ $t("label.email2") }}</div>
                <div class="contact-item-value">{{ item.email }}</div>
              </div>
              <!-- <div class="contact-item">
                <div class="contact-item-label">所属区域</div>
                <div class="contact-item-value">{{ item.name }}</div>
              </div>
              <div class="contact-item">
                <div class="contact-item-label">负责人</div>
                <div class="contact-item-value">{{ item.name }}</div>
              </div> -->
              <div class="contact-item-btn">
                <div class="contact-btn" @click="chooseContact(item)">
                  {{ $t("label.tabpage.choose") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as controlPanelApi from "../api.js";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";

export default {
  name: "choose-contact",
  components: {
    createEditObj,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      contactList: [],
      contactNum: 0,
    };
  },
  methods: {
    // 新增/修改保存
    save(id) {
      let conf = {
        caseid: this.id,
        contactid: id,
      };
      controlPanelApi
        .associateContact(conf)
        .then((res) => {
          if (res.result) {
            this.$emit("closeCard", "fresh");
          }
        })
        .catch(() => {});
    },
    // 新建联系人
    newContact() {
      this.$refs.createEditObj.add();
    },
    /**
     * 渲染，父组件中ref调用
     */
    async render() {
      let conf = { caseid: this.id };
      let res = await controlPanelApi.identifyContact(conf);
      if (res.result) {
        this.contactList = res.data.contactList;
        this.contactNum = res.data.contactListsize;
      }
    },
    newTabDetail(item) {
      const newPage = this.$router.resolve({
        path: `/commonObjects/detail/${item.id}/DETAIL`,
      });
      window.open(newPage.href, "_blank");
    },
    goBackCase() {
      this.$emit("closeCard");
    },
    chooseContact(item) {
      this.save(item.id);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.container {
  // position: relative;
  .header {
    height: 62px;
    width: 388px;
    position: absolute;
    top: 0;
    background: #fafaf9;
    border: 1px solid #dedcda;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      font-size: 16px;
    }
    .header-title {
      font-size: 16px;
      color: #080707;
      line-height: 21px;
    }
  }
  .blank-space {
    height: 62px;
  }
  .content-box {
    height: calc(100% - 62px);
    overflow: auto;
    .center {
      padding: 20px 40px 20px 40px;
      display: flex;
      align-items: center;
      .header-icon {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        // border: 2px solid #D0D0D0;
      }
      .header-name {
        font-size: 20px;
        font-weight: bold;
        color: #080707;
        line-height: 27px;
      }
    }
    .footer {
      .footer-tab {
        width: 100%;
        height: 36px;
        background: #fafaf9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #666666;
      }
      .no-contact {
        padding: 20px 44px;
        img {
          width: 300px;
          height: 300px;
          margin-bottom: 20px;
        }
        .new-contact {
          display: flex;
          justify-content: center;
          .new-contact-btn {
            padding: 6px 16px;
            cursor: pointer;
            height: 30px;
            // width: 100px;
            margin: 0 auto;
            background: #006dcc;
            border-radius: 3px;
            font-size: 12px;
            color: #ffffff;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .contact-list {
        padding: 20px 40px;
        .contact-list-item {
          width: 308px;
          height: 190px;
          background: #ffffff;
          box-shadow: 0px 0px 12px 0px rgba(0, 12, 24, 0.3);
          border-radius: 4px;
          margin-bottom: 20px;
          padding: 18px 20px 16px 20px;
          position: relative;
          .header-icon {
            cursor: pointer;
            height: 16px;
            width: 16px;
            position: absolute;
            right: 20px;
            top: 18px;
          }
          .contact-item {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            &-label {
              width: 64px;
              height: 16px;
              font-size: 12px;
              color: #666666;
              line-height: 16px;
            }
            #-value {
              height: 20px;
              font-size: 14px;
              font-weight: bold;
              color: #333333;
              line-height: 19px;
            }
          }
          .contact-item-btn {
            display: flex;
            justify-content: flex-end;
            .contact-btn {
              padding: 4px 13px;
              cursor: pointer;
              height: 30px;
              // width: 50px;
              background: #006dcc;
              border-radius: 3px;
              font-size: 12px;
              color: #ffffff;
              line-height: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
</style>